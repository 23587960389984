import React from 'react'
import SvgShell from './SvgShell'

const Group = props => {
	const style = {
		fill: 'none',
		stroke: 'currentColor',
		strokeLinecap: 'round',
		strokeLinejoin: 'round',
		strokeWidth: 16,
	}
	return (
		<SvgShell {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
				<rect width="256" height="256" fill="none" />
				<circle
					cx="88.00033"
					cy="108"
					r="52"
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					strokeWidth="16"
				/>
				<path d="M155.41251,57.937A52.00595,52.00595,0,1,1,169.52209,160" style={style} />
				<path d="M15.99613,197.39669a88.01736,88.01736,0,0,1,144.00452-.00549" style={style} />
				<path d="M169.52209,160a87.89491,87.89491,0,0,1,72.00032,37.3912" style={style} />
			</svg>
		</SvgShell>
	)
}

export default Group
