import React, { useContext, useState } from 'react'
import { TextSpan } from '../../reusable_components/Typography'
import { CenteredSection, Divider, DynamicPageSection } from '../../reusable_components/layout/PageComponents'
import Button from '../../reusable_components/buttons/Button'
import { theme } from '../../../utils/theme'
import ContentSideBarContext from '../../../contexts/content_side_bar/ContentSideBarContext'
import CourseDateCard from '../../reusable_components/course_cards/CourseDateCard'

const CourseAndItsDates = ({ t, course }) => {
	const { title, reviewData, bookableDates = [], url } = course
	const { state, dispatch } = useContext(ContentSideBarContext)
	const [numberOfShownDates, setNumberOfShownDates] = useState(2)
	const openCoursePage = event => {
		// The card is an a tag link so it can be indexed by Google, but we want the overlay to open instead of
		// the user being rerouted to the course page, so prevent the default of the a tag.
		event.preventDefault()
		dispatch({ type: 'NEW_CONTENT', data: { content: course, contentType: 'COURSE' } })
	}
	return (
		<div>
			<a href={url} onClick={openCoursePage}>
				<p>
					{title}&nbsp;&nbsp;&nbsp;
					{reviewData.count > 0 ? (
						<>
							<TextSpan textColor="orange">★&nbsp;</TextSpan>
							<TextSpan textColor="lightTextColor">
								{reviewData.rating} ({reviewData.count} {t('copy:coursePage.reviews')})
							</TextSpan>
						</>
					) : (
						<TextSpan textColor="lightTextColor">{t('common:workshop.new')}</TextSpan>
					)}
				</p>
			</a>
			<br />
			<br />
			{bookableDates?.slice(0, numberOfShownDates).map((courseDate, key) => (
				<CourseDateCard key={key} course={course} courseDate={courseDate} />
			))}
			{numberOfShownDates < bookableDates.length && (
				<DynamicPageSection margins={{ bottom: theme.margins.smallInt }}>
					<CenteredSection>
						<Button color="TRANSPARENT_BLACK" onClick={() => setNumberOfShownDates(numberOfShownDates + 4)}>
							{t('common:buttons.seeMoreDates')}
						</Button>
					</CenteredSection>
				</DynamicPageSection>
			)}
			<DynamicPageSection margins={{ bottom: theme.margins.largeInt }}>
				<Divider color={theme.colors.gray} />
			</DynamicPageSection>
		</div>
	)
}

export default CourseAndItsDates
