import React, { useEffect, useState } from 'react'
import Share from '../svg_icons/Share'

const ShareButton = ({ title, text, fill }) => {
	const [loaded, setLoaded] = useState(false)
	const onClick = async () => {
		try {
			// Open the mobile's native share tool
			await navigator.share({ title, text, url: location.href })
		} catch (err) {
			console.log('Error: ', err)
		}
	}
	useEffect(() => {
		setLoaded(true)
	}, [])
	// Only display the share button if the client supports native phone sharing (navigator.share)
	return loaded && navigator.share ? (
		<Share fill={fill} width={22} height={22} onClick={onClick} id="GTM_SHARE_BUTTON" />
	) : null
}

export default ShareButton
