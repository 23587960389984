import React from 'react'
import SvgShell from './SvgShell'

const RadioButtonChecked = props => (
	<SvgShell {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path d="M12 7c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0-5C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z" />
			<path d="M0 0h24v24H0z" fill="none" />
		</svg>
	</SvgShell>
)

export default RadioButtonChecked
