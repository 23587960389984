import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { isServerRendering } from '../../../utils/applicationHelper'
import Button, { LoaderAnimation } from '../buttons/Button'
import { CenteredSmallPageSection, PageSection } from '../layout/PageComponents'

// A wrapper around paginateable items.
// The Paginator loads more items from the server when scrolled to the bottom of its parent

class PaginateWithScroll extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			body: isServerRendering() ? null : document.getElementsByTagName('html')[0],
		}
		this.onScroll = this.onScroll.bind(this)
	}
	componentDidMount() {
		if (!this.state.courseCardsWrapperRef) {
			this.setState({
				courseCardsWrapperRef: document.getElementById(this.props.courseCardsWrapperId),
			})
		}
		if (this.props.hasMore) {
			window.addEventListener('scroll', this.onScroll)
		}
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll)
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { hasMore, paginate, loading, forceAPagination } = this.props
		if (prevProps.hasMore && !hasMore) {
			// There are no more items to load, so remove scroll listener
			window.removeEventListener('scroll', this.onScroll)
		}
		if (!prevProps.hasMore && hasMore) {
			// There used to not be any more items to load, but now there are. Perhaps it was reset after a new filter was applied
			window.addEventListener('scroll', this.onScroll)
		}
		if (forceAPagination) paginate()
		if (!hasMore || loading) {
			return
		}
		if (!this.isScrollable()) {
			// Items have been loaded and there are more items to load, but there aren't enough items to make the page
			// scrollable and trigger this.onScroll(), so paginate until it becomes scrollable
			paginate()
		}
	}

	onScroll() {
		// This is called on every scroll, so don't use variables to increase performance since they need to be added to RAM
		if (this.props.loading || !this.state.courseCardsWrapperRef?.offsetTop) {
			return
		}
		if (
			this.state.body.clientHeight + window.scrollY >
			this.state.courseCardsWrapperRef.offsetTop + this.state.courseCardsWrapperRef.scrollHeight - 200
		) {
			this.props.paginate()
		}
	}
	isScrollable() {
		const { body } = this.state
		return body.scrollHeight > body.clientHeight
	}

	render() {
		const { children, useLoadingAnimation, loading } = this.props
		return (
			<>
				{children}
				{loading && useLoadingAnimation && (
					<PageSection>
						<LoaderAnimation color="black" />
					</PageSection>
				)}
			</>
		)
	}
}

const PaginateWithButton = props => {
	const { children, paginate, hasMore, loading, t } = props
	useEffect(() => {
		if (loading) paginate()
	}, [])
	return (
		<>
			{children}
			{hasMore && (
				<CenteredSmallPageSection>
					<Button submitted={loading} color="OUTLINED_BLACK" onClick={() => paginate()}>
						{t('common:buttons.seeMoreWorkshops')}
					</Button>
				</CenteredSmallPageSection>
			)}
		</>
	)
}
const Paginator = ({ paginateWithButton, children, ...rest }) => {
	return paginateWithButton ? (
		<PaginateWithButton {...rest}>{children}</PaginateWithButton>
	) : (
		<PaginateWithScroll {...rest}>{children}</PaginateWithScroll>
	)
}

Paginator.propTypes = {
	paginate: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	hasMore: PropTypes.bool,
	paginateWithButton: PropTypes.bool, // Paginate with a "See more" button instead of paginating by scrolling down
	courseCardsWrapperId: PropTypes.string.isRequired,
	useLoadingAnimation: PropTypes.bool,
}

export default Paginator
