import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next-new'
import styled from 'styled-components'

import {
	Divider,
	SmallPageSection,
	SmallPageSectionRepulsiveRow,
} from '../../../../reusable_components/layout/PageComponents'
import CrocodileRight from '../../../../reusable_components/svg_icons/CrocodileRight'
import QRCode from 'react-qr-code'
import DynamicModal from '../../../../reusable_components/modals/DynamicModal'
import { P2 } from '../../../../reusable_components/Typography'
import Slider from 'react-slick'
import { theme } from '../../../../../utils/theme'

const Wrapper = styled.div`
	display: flex !important;
	flex-direction: column;
	align-items: center;
`

const SliderWrapper = styled.div`
	overflow: hidden;
	> div:last-child {
		margin-top: ${theme.margins.large};
		display: flex;
		justify-content: center;
		> svg:first-child {
			margin-right: ${theme.margins.large};
			transform: rotateZ(180deg);
		}
	}
`

const QrCodesModal = ({ isBooker, ticket, tickets }) => {
	const { t, i18n } = useTranslation()
	const [showQrModal, setShowQrModal] = useState({})
	// If it's the booker and they have multiple tickets, allow them to see all of the qr codes so their guests don't have to show them
	const qrCodes = showQrModal.type === 'SINGLE' ? [ticket] : tickets
	const sliderRef = useRef(null)
	const [allowLeftSlide, setAllowLeftSlide] = useState(false)
	const [allowRightSlide, setAllowRightSlide] = useState(true)
	const beforeChange = (currentIndex, nextIndex) => {
		if (nextIndex === 0) {
			// The slider is at the start
			setAllowLeftSlide(false)
		} else if (!allowLeftSlide) {
			// The slider was at the start, but is not longer at the start
			setAllowLeftSlide(true)
		}
		if (nextIndex + 1 >= tickets.length) {
			// The slider is at the end
			setAllowRightSlide(false)
		} else if (!allowRightSlide) {
			// The slider was at the end, but is not longer at the end
			setAllowRightSlide(true)
		}
	}
	return (
		<>
			<SmallPageSectionRepulsiveRow onClick={() => setShowQrModal({ show: true, type: 'SINGLE' })}>
				{t('admin:ticketsPage.showQrCode')}
				<CrocodileRight />
			</SmallPageSectionRepulsiveRow>
			{isBooker && tickets.length > 1 && (
				<>
					<Divider />
					<SmallPageSectionRepulsiveRow onClick={() => setShowQrModal({ show: true, type: 'MULTIPLE' })}>
						{t('admin:ticketsPage.showQrCodes')}
						<CrocodileRight />
					</SmallPageSectionRepulsiveRow>
				</>
			)}
			<DynamicModal
				displayModal={showQrModal.show}
				setDisplayModal={show => setShowQrModal({ show, type: showQrModal.type })}
			>
				{qrCodes.length > 1 ? (
					<SliderWrapper>
						<Slider
							{...{
								dots: false,
								infinite: false,
								slidesToShow: 1,
								arrows: false,
								swipeToSlide: true,
								ref: sliderRef,
								beforeChange: beforeChange,
							}}
						>
							{qrCodes.map(({ qrCode, fullName, id }, index) => (
								<Wrapper>
									<p>
										{index + 1}/{qrCodes.length}
									</p>
									<P2>
										{t('admin:courseDatePage.qrParticipant', {
											name: fullName || t('admin:courseDatePage.noName'),
										})}
									</P2>
									<p>{t('admin:courseDatePage.ticketId', { id })}</p>
									<SmallPageSection />
									<QRCode value={qrCode} />
								</Wrapper>
							))}
						</Slider>
						<div>
							<CrocodileRight
								width="40px"
								height="40px"
								style={{ opacity: allowLeftSlide ? 1 : 0.3 }}
								onClick={() => allowLeftSlide && sliderRef.current.slickPrev()}
							/>
							<CrocodileRight
								width="40px"
								height="40px"
								style={{ opacity: allowRightSlide ? 1 : 0.3 }}
								onClick={() => allowRightSlide && sliderRef.current.slickNext()}
							/>
						</div>
					</SliderWrapper>
				) : (
					<Wrapper>
						<P2>
							{t('admin:courseDatePage.qrParticipant', {
								name: ticket.fullName || t('admin:courseDatePage.noName'),
							})}
						</P2>
						<p>{t('admin:courseDatePage.ticketId', { id: ticket.id })}</p>
						<SmallPageSection />
						<QRCode value={ticket.qrCode} />
					</Wrapper>
				)}
			</DynamicModal>
		</>
	)
}

export default QrCodesModal
