export default (elementIdToScrollTo, flashElement = false, callback) => {
	const targetElement = document.getElementById(elementIdToScrollTo)
	if (targetElement) {
		// NOTE: IF YOU NOTICE THAT IT SCROLLS TOO LONG, MAKE SURE THERE IS NO TOP MARGIN ON THE FIRST CHILD OF targetElement. USE TOP PADDING INSTEAD ON THE targetElement
		const header = document.getElementById('HEADER_OPACITY_DIV')
		targetElement.style.scrollMarginTop = header.offsetHeight - 1 + 'px' // Add this so it scrolls to just under the header
		targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
		if (callback) {
			callback()
		}
		if (flashElement) {
			flash(targetElement)
		}
		targetElement.style.scrollMarginTop = ''
	}
}

// Highlight element by flashing it
function flash(targetElement) {
	targetElement.style.transition = 'opacity 0.5s'
	targetElement.style.opacity = '1'
	targetElement.style.opacity = '0.2'
	setTimeout(unFlash, 500, targetElement)
}
function unFlash(targetElement) {
	targetElement.style.opacity = '1'
	setTimeout(resetElement, 500, targetElement)
}
function resetElement(targetElement) {
	targetElement.style.transition = ''
	targetElement.style.opacity = ''
}
