import React from 'react'
import { theme } from '../../../utils/theme'
// import Slider from 'react-slick'
// import CrocodileRight from '../../reusable_components/svg_icons/CrocodileRight'
import ResponsiveImage from '../../reusable_components/responsive/ResponsiveImage'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import { HeaderWrapper } from './HeaderSection'

/*const SliderButton = styled.div`
	height: 100%;
	color: white;
	justify-content: center;
	align-items: center;
	right: ${({ right }) => (right ? 0 : 'unset')};
	bottom: 0;
	position: absolute;
	display: flex;
	cursor: pointer;
	z-index: 1;
	> svg {
		transform: rotate(${({ right }) => (right ? 0 : '180')}deg);
		width: 60px;
		height: 60px;
		${theme.breakpoints.phone.range} {
			width: 50px;
			height: 50px;
		}
	}
`

const NextArrow = ({ onClick }) => {
	return (
		<SliderButton right onClick={onClick}>
			<CrocodileRight />
		</SliderButton>
	)
}

const PrevArrow = () => {
	return null
}*/

const ContentSlider = ({ image, title, isOpenedInContentSideBar, sideBarHeader }) => {
	return (
		<HeaderWrapper>
			{sideBarHeader}
			<ContentSliderImage image={image} alt={title} isOpenedInContentSideBar={isOpenedInContentSideBar} />
			<div />
		</HeaderWrapper>
	)
	/*const settings = {
		infinite: true,
		slidesToShow: 1,
		speed: 500,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
	}
	let sliderRef = useRef(null)
	const nextSlide = event => {
		if (event.keyCode === 39) sliderRef.slickNext()
	}
	const previousSlide = event => {
		if (event.keyCode === 37) sliderRef.slickPrev()
	}
	useEffect(() => {
		window.addEventListener('keyup', nextSlide)
		window.addEventListener('keyup', previousSlide)
		return () => {
			window.removeEventListener('keyup', nextSlide)
			window.removeEventListener('keyup', previousSlide)
		}
	}, [nextSlide, previousSlide])
	return (
		<Slider ref={slider => (sliderRef = slider)} {...settings}>
			{images.map((image, index) => (
				<div key={index}>
					<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndExcluding}>
						<ResponsiveImage
							imagePath={image}
							type="BIG_VERTICAL_RECTANGLE"
							alt={title}
							sourceWidth={theme.breakpoints.phone.maxInt}
						/>
					</Breakpoint>
					<Breakpoint breakpoint={theme.breakpoints.tablet.range}>
						<ResponsiveImage
							imagePath={image}
							type="SQUARE"
							alt={title}
							sourceWidth={theme.breakpoints.tablet.maxInt}
						/>
					</Breakpoint>
					<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
						<ResponsiveImage
							imagePath={image}
							type="RECTANGLE"
							alt={title}
							sourceWidth={theme.widths.pageMaxWidthIncludingPaddingInt}
						/>
					</Breakpoint>
				</div>
			))}
		</Slider>
	)*/
}

export const ContentSliderImage = ({ image, alt, isOpenedInContentSideBar }) => (
	<>
		<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndExcluding}>
			<ResponsiveImage
				imagePath={image}
				type="BIG_VERTICAL_RECTANGLE"
				alt={alt}
				sourceWidth={theme.breakpoints.phone.maxInt}
			/>
		</Breakpoint>
		<Breakpoint breakpoint={theme.breakpoints.tablet.range}>
			<ResponsiveImage imagePath={image} type="SQUARE" alt={alt} sourceWidth={theme.breakpoints.tablet.maxInt} />
		</Breakpoint>
		<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
			{isOpenedInContentSideBar ? (
				<ResponsiveImage
					imagePath={image}
					type="RECTANGLE"
					alt={alt}
					sourceWidth={theme.breakpoints.largeLaptop.maxInt}
				/>
			) : (
				<>
					<ResponsiveImage
						imagePath={image}
						type="THIN_RECTANGLE"
						alt={alt}
						sourceWidth={theme.breakpoints.largeLaptop.maxInt}
					/>
				</>
			)}
		</Breakpoint>
	</>
)

export default ContentSlider
