import React, { useEffect, useState } from 'react'
import ScreenSizeContext from './ScreenSizeContext'
import { getCurrentScreenType, getCurrentScreenTypes } from '../../utils/screenSizeHelper'
import { Context as ResponsiveContext } from 'react-responsive'
import { theme } from '../../utils/theme'

/*
 * A wrapper used to detect changes in screen type. Listen to the ScreenSizeContext in a child to this wrapper to
 * detect changes in the screen type. At the moment it only fires an update if the screen type changes for performance reasons,
 * but we could also add an option to fire an update on every screen size update.
 *
 * */

const ScreenSizeProvider = ({ children, railsContext }) => {
	const [state, setState] = useState({})
	const screenTypes = getCurrentScreenTypes(railsContext)
	const screenType = getCurrentScreenType(railsContext)

	const resizeListener = () => {
		const currentScreenTypes = getCurrentScreenTypes()
		// Update the screen type if the current screen type is different from the one saved in the state
		for (const [screenType, screenTypeValue] of Object.entries(screenTypes)) {
			if (!screenTypeValue && currentScreenTypes[screenType]) {
				setState({
					...state,
					width: window.innerWidth,
					screenTypes: currentScreenTypes,
					screenType: getCurrentScreenType(),
				})
				break
			}
		}
	}

	useEffect(() => {
		setState({
			width: window.innerWidth,
		})
	}, [])

	useEffect(() => {
		window.addEventListener('resize', resizeListener)
		return () => {
			window.removeEventListener('resize', resizeListener)
		}
	}, [resizeListener])

	let width
	if (state.width) {
		// The client has initially rendered with the same guessed width param as the SSR
		// Now we need to set width to the actual window's inner width
		width = state.width
	} else if (railsContext.phone) {
		width = theme.breakpoints.phone.maxInt
	} else if (railsContext.tablet) {
		width = theme.breakpoints.tablet.maxInt
	} else if (railsContext.desktop) {
		width = theme.breakpoints.laptop.maxInt
	} else {
		width = theme.breakpoints.largeLaptop.maxInt
	}

	return (
		<ScreenSizeContext.Provider
			value={{
				width,
				screenTypes,
				screenType,
			}}
		>
			<ResponsiveContext.Provider value={{ width }}>{children}</ResponsiveContext.Provider>
		</ScreenSizeContext.Provider>
	)
}

export default ScreenSizeProvider
