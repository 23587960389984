import React, { useEffect, useRef, useState } from 'react'
import SearchBar from './SearchBar'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { PageSidePaddingWrapper } from '../layout/PageComponents'
import { theme } from '../../../utils/theme'
import Breakpoint from '../responsive/Breakpoint'

const searchHeight = 100
const StyledSearchContainer = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	z-index: 6;
	top: ${({ searchIsOpen, headerHeight, searchInputHeight }) =>
		searchIsOpen ? -(searchHeight - headerHeight) + searchInputHeight - 1 : -(searchHeight - headerHeight)}px;
	opacity: ${({ searchIsOpen, transparentHeader }) => (transparentHeader ? (searchIsOpen ? 1 : 0) : 1)};
	transition:
		opacity 0.3s,
		top 0.3s;
	height: ${searchHeight}px;
	background: ${theme.colors.lightGray};
	box-shadow: ${({ searchIsOpen }) => (searchIsOpen ? `0 -10px 25px ${theme.colors.darkGray}` : 'none')};
`
let searchInputHeight = 46
const SearchContainer = ({ t, searchIsOpen, setSearchIsOpen, searchIconRef, transparentHeader }) => {
	const ref = useRef(null)
	const [headerHeight, setHeaderHeight] = useState(55)
	const history = useHistory()
	useEffect(() => {
		searchInputHeight = document.getElementById('AUTOSUGGEST_INPUT').offsetHeight
		setHeaderHeight(document.getElementById('HEADER_OPACITY_DIV').offsetHeight)
	}, [])

	useEffect(() => {
		const closeSearchBar = () => setSearchIsOpen(false)

		// Add a click listener to close the search bar when the user clicks outside of it
		const clickListener = event => {
			if (searchIsOpen && !ref.current?.contains(event.target) && !searchIconRef.current?.contains(event.target)) {
				closeSearchBar()
			}
		}
		// Add a keyup listener to close the search bar when the user clicks the 'esc' button
		const keyBoardListener = event => {
			if (searchIsOpen && event.keyCode === 27) {
				closeSearchBar()
			}
		}
		const eventListenerOptions = { capture: true }
		if (searchIsOpen) {
			window.addEventListener('popstate', closeSearchBar)
			window.addEventListener('click', clickListener, eventListenerOptions)
			window.addEventListener('keyup', keyBoardListener)
		} else {
			window.removeEventListener('popstate', closeSearchBar)
			window.removeEventListener('click', clickListener, eventListenerOptions)
			window.removeEventListener('keyup', keyBoardListener)
		}
		return () => {
			window.removeEventListener('popstate', closeSearchBar)
			window.removeEventListener('click', clickListener, eventListenerOptions)
			window.removeEventListener('keyup', keyBoardListener)
		}
	}, [searchIsOpen, setSearchIsOpen, searchIconRef])
	return (
		<StyledSearchContainer
			ref={ref}
			searchIsOpen={searchIsOpen}
			headerHeight={headerHeight}
			searchInputHeight={searchInputHeight}
			transparentHeader={transparentHeader}
		>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
				<SearchBar t={t} searchIsOpen={searchIsOpen} history={history} setSearchIsOpen={setSearchIsOpen} />
			</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
				<PageSidePaddingWrapper>
					<SearchBar t={t} searchIsOpen={searchIsOpen} history={history} setSearchIsOpen={setSearchIsOpen} />
				</PageSidePaddingWrapper>
			</Breakpoint>
		</StyledSearchContainer>
	)
}

export default SearchContainer
