import React from 'react'
import SvgShell from './SvgShell'

const Envelope = props => {
	const envelopeStyle = { fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round' }
	return (
		<SvgShell {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 19">
				<path style={envelopeStyle} d="M28,7,16,18,4,7" transform="translate(-3.5 -6.5)" />
				<path style={envelopeStyle} d="M4,7H28V24a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1Z" transform="translate(-3.5 -6.5)" />
				<path style={envelopeStyle} d="M13.82,16,4.31,24.72" transform="translate(-3.5 -6.5)" />
				<path style={envelopeStyle} d="M27.69,24.72,18.18,16" transform="translate(-3.5 -6.5)" />
			</svg>
		</SvgShell>
	)
}

export default Envelope
