import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Button from './Button'
import UserContext from '../../../contexts/user/UserContext'
import { useTranslation } from 'react-i18next-new'
import HeartFilled from '../svg_icons/HeartFilled'
import HeartOutlined from '../svg_icons/HeartOutlined'
import { theme } from '../../../utils/theme'
import Breakpoint from '../responsive/Breakpoint'
import { P4 } from '../Typography'

const FollowButton = ({
	favouritableType,
	favouritableId,
	followText,
	followingText,
	color,
	buttonType,
	wide,
	showHeart,
	gtmId,
	skipButtonWhenFavourited,
}) => {
	if (typeof favouritableId === 'string') favouritableId = parseInt(favouritableId)
	const { userState, dispatch } = useContext(UserContext)
	const { t } = useTranslation()
	const user = userState.user
	const favourites = user.favourites
	const [submitted, setSubmitted] = useState(false)
	const favourited = favourites[favouritableType]?.includes(favouritableId)
	const onClick = () => {
		const signedIn = user.signedIn
		if (favourited) return
		const requestNewFavourite = user => {
			const favourites = user.favourites
			const onFavouriteCompleted = () => {
				favourites[favouritableType] = [...favourites[favouritableType], favouritableId]
				dispatch({ type: 'NEW_FAVOURITE', data: favourites })
			}
			setSubmitted(false)
			axios({
				method: 'post',
				url: '/admin/account/favourites/',
				data: {
					id: favouritableId,
					favouritableType: favouritableType,
				},
			})
				.then(response => {
					onFavouriteCompleted()
				})
				.catch(error => {
					if (error.response.status === 409) {
						onFavouriteCompleted()
					}
				})
		}
		if (signedIn) {
			requestNewFavourite(user)
		} else {
			dispatch({
				type: 'SESSION_MODAL_DISPLAY_STATE',
				data: {
					displayState: 'FAVOURITE',
					title: t(`copy:sessionModal.registerToFavourite${favouritableType}`),
					message: t(`copy:sessionModal.registerToFavouriteBody${favouritableType}`),
					initiateOnboarding: false,
					callback: user => requestNewFavourite(user),
				},
			})
		}
	}

	if (!followText) {
		if (favouritableType === 'hostPage') {
			followText = t('common:buttons.followMe')
		} else if (favouritableType === 'course') {
			followText = t('common:buttons.notifyMe')
		} else if (favouritableType === 'subCategory') {
			followText = t('common:buttons.follow')
		}
	}
	if (!followingText) {
		if (favouritableType === 'hostPage') {
			followingText = t('common:buttons.following')
		} else if (favouritableType === 'course') {
			followingText = t('common:buttons.notifyingMe')
		} else if (favouritableType === 'subCategory') {
			followingText = t('common:buttons.following')
		}
	}
	return skipButtonWhenFavourited && favourited ? (
		<P4 textColor="lightTextColor">{followingText}</P4>
	) : (
		<Button
			disable={favourited}
			hideDisabledCursor
			submitted={submitted}
			onClick={onClick}
			color={color}
			buttonType={buttonType}
			wide={wide}
			gtmId={gtmId}
		>
			{favourited ? (
				<>
					{showHeart ? (
						<>
							<HeartFilled width="16px" height="16px" />
							<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
								<div>{followingText}</div>
							</Breakpoint>
						</>
					) : (
						followingText
					)}
				</>
			) : (
				<>
					{showHeart ? (
						<>
							<HeartOutlined width="16px" height="16px" />
							<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
								<div>{followText}</div>
							</Breakpoint>
						</>
					) : (
						followText
					)}
				</>
			)}
		</Button>
	)
}

FollowButton.propTypes = {
	favouritableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	// favouritableType: PropTypes.oneOf(['hostPage', 'course', 'subCategory']).isRequired,
	text: PropTypes.string,
	wide: PropTypes.bool,
	skipButtonWhenFavourited: PropTypes.bool, // Show only the favourited text when favourited, and skip the button
	// buttonType: PropTypes.oneOf(['ROUND', 'RECTANGLE', 'LINK']),
	/*color: PropTypes.oneOf([
		'WHITE',
		'BLACK',
		'ORANGE',
		'GRAY',
		'GOLD',
		'OUTLINED_BLACK',
		'OUTLINED_WHITE',
		'TRANSPARENT_BLACK',
		'TRANSPARENT_WHITE',
	]),*/
}

export default FollowButton
