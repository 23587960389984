import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import UserContext from '../../../contexts/user/UserContext'
import { theme } from '../../../utils/theme'
import Button from './Button'
import InstallDesktop from '../svg_icons/InstallDesktop'
import InstallMobile from '../svg_icons/InstallMobile'
import Lightning from '../svg_icons/Lightning'
import { LeftAlignedFlexBox } from '../layout/PageComponents'
import ScreenSizeContext from '../../../contexts/screen_size/ScreenSizeContext'
import { isIosDevice, isManifestLoaded } from '../../../utils/applicationHelper'
import DynamicModal from '../modals/DynamicModal'
import { LeftAlignedOrderedList } from '../Typography'
import IosShare from '../svg_icons/IosShare'

const Wrapper = styled.div`
	display: flex;
	${({ columnMode }) =>
		columnMode
			? css`
					flex-direction: column;
					text-align: center;
					> button {
						margin-top: ${theme.margins.XSmall};
					}
				`
			: css`
					> button {
						margin-left: ${theme.margins.XSmall};
					}
				`}
	justify-content: space-between;
	align-items: center;
	background: ${theme.colors.fade(theme.colors.orange, 0.8)};
	color: ${theme.colors.white};
	svg {
		fill: ${theme.colors.textColor};
	}
	padding: ${theme.paddings.XSmall};
	border-radius: 5px;
`

function PwaButton(props) {
	const { t, adminSideBar } = props
	const { screenType } = useContext(ScreenSizeContext)
	const [displayIosModal, setDisplayIosModal] = useState(false)
	const mobile = ['phone', 'tablet'].includes(screenType)
	const { userState, dispatch } = useContext(UserContext)
	const pwaReadyEvent = userState.pwa.readyEvent
	useEffect(() => {}, [pwaReadyEvent])
	// Don't render the PWA button if:
	// 1. Manifest isn't loaded, i.e. the page wasn't rendered in host admin. This is to prevent e.g. participants to install the PWA
	// 2. The website is opened in the PWA, so no use to ask them to install it
	// 3. The website is opened in the browser (not on iOS), but the pwa event isn't ready yet
	if (!isManifestLoaded() || userState.pwa.openedInPwa || (!isIosDevice() && !pwaReadyEvent)) return null
	const onClick = () => {
		if (isIosDevice()) {
			// iOS only allows PWA to be installed via their share button, and then "Add to home screen"
			setDisplayIosModal(true)
		} else {
			pwaReadyEvent.prompt()
		}
	}
	return (
		<>
			{adminSideBar ? (
				<LeftAlignedFlexBox onClick={onClick}>
					<span>{t('common:buttons.install')}</span>
					{mobile ? <InstallMobile /> : <InstallDesktop />}
				</LeftAlignedFlexBox>
			) : (
				<Wrapper columnMode={mobile}>
					<LeftAlignedFlexBox>
						{!mobile && <Lightning />}
						{mobile ? t('shared:userMenu.installPwaMobile') : t('shared:userMenu.installPwaDesktop')}
					</LeftAlignedFlexBox>
					<Button color="OUTLINED_BLACK" onClick={onClick}>
						<span>{t('common:buttons.install')}</span>
						{mobile ? <InstallMobile /> : <InstallDesktop />}
					</Button>
				</Wrapper>
			)}
			<DynamicModal displayModal={displayIosModal} setDisplayModal={setDisplayIosModal}>
				<h2>{t('shared:userMenu.installPwaModalTitle')}</h2>
				<p>{t('shared:userMenu.installPwaModalDescription')}</p>
				<br />
				<LeftAlignedOrderedList>
					<li>
						<LeftAlignedFlexBox innerMargin={theme.margins.XXSmall}>
							<p>{t('shared:userMenu.installPwaModalPoint1')}</p>
							<IosShare
								onClick={() => {
									navigator?.share && navigator?.share()
								}}
								width={18}
								height={18}
							/>
						</LeftAlignedFlexBox>
					</li>
					<li>
						<LeftAlignedFlexBox>{t('shared:userMenu.installPwaModalPoint2')}</LeftAlignedFlexBox>
					</li>
					<li>
						<LeftAlignedFlexBox>{t('shared:userMenu.installPwaModalPoint3')}</LeftAlignedFlexBox>
					</li>
				</LeftAlignedOrderedList>
			</DynamicModal>
		</>
	)
}

export default PwaButton
