import React, { useEffect, useState } from 'react'
import { theme } from '../../../utils/theme'
import gql from 'graphql-tag'
import { useQuery } from 'urql'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next-new'

import Rating from './Rating'
import { CenteredSection, ContentRow, Divider } from '../layout/PageComponents'
import { ProfilePicture } from '../other/CourseComponents'
import { Text, TextSpan } from '../Typography'
import ReadMore from '../responsive/ReadMore'
import Button from '../buttons/Button'
import LoadingSpinner from '../other/LoadingSpinner'
import LoadContainer from '../../admin/layout/LoadContainer'

const Review = styled.div`
	border-bottom: 1px solid ${theme.colors.gray};
	padding-bottom: ${theme.paddings.large};
	> div:first-child {
		display: flex;
		align-items: center;
		margin-bottom: ${theme.margins.XXSmall};
		> div:first-child {
			margin-right: ${theme.margins.XSmall};
		}
	}
`

const ReviewList = ({ reviewData, title, displayCourse = false, setReviewsCount, reviewsCount, reviews, fetching }) => {
	const { rating, count } = reviewData
	const { t } = useTranslation()
	if (count === 0 || !reviews?.length) return null
	return (
		<>
			<Divider />
			<ContentRow
				margins={{ inner: theme.margins.largeInt }}
				title={
					title || (
						<div>
							<TextSpan textColor="orange">★&nbsp;</TextSpan>
							{rating} ({count} {t('copy:coursePage.reviews')})
						</div>
					)
				}
				bottomContent={
					fetching ? (
						<CenteredSection>
							<LoadingSpinner />
						</CenteredSection>
					) : (
						<>
							{reviewsCount < count && (
								<CenteredSection>
									<Button color="OUTLINED_BLACK" onClick={() => setReviewsCount(reviewsCount + 4)}>
										{t('common:buttons.moreReviews')}
									</Button>
								</CenteredSection>
							)}
						</>
					)
				}
				childrenPerRowDesktop={2}
			>
				{reviews.map((review, key) => (
					<Review key={key}>
						<div>
							<div>
								<ProfilePicture profileName={review.fullName} imagePath={review.image} />
							</div>
							<div>
								<div>{review.firstName}</div>
								<Text textColor="lightTextColor">{review.createdAt}</Text>
								<div>{displayCourse && review.course.title}</div>
								<Rating rating={review.rating} />
							</div>
						</div>
						<ReadMore maxLines={3}>
							<p>{review.review}</p>
						</ReadMore>
					</Review>
				))}
			</ContentRow>
		</>
	)
}
const getCourseReviews = gql`
	query ($id: ID!, $limit: Int!) {
		course(id: $id) {
			reviewData(limit: $limit) {
				createdAt
				rating
				review
				fullName
				image
				firstName
			}
		}
	}
`
const getHostPageReviews = gql`
	query ($id: ID!, $limit: Int!) {
		hostPage(id: $id) {
			hostPageReviewData(limit: $limit) {
				createdAt
				rating
				review
				course {
					title
				}
				fullName
				image
				firstName
			}
		}
	}
`
export const CourseDateReviewList = props => {
	const [reviewsCount, setReviewsCount] = useState(4)
	return (
		<ReviewList
			reviews={props.reviewData.reviews.slice(0, reviewsCount)}
			reviewsCount={reviewsCount}
			setReviewsCount={setReviewsCount}
			reviewData={props.reviewData}
		/>
	)
}
export const HostPageReviewList = ({ hostPageId, ...rest }) => {
	const [reviewsCount, setReviewsCount] = useState(4)
	const [initialFetch, setInitialFetch] = useState(true)
	const [result] = useQuery({
		query: getHostPageReviews,
		variables: { id: hostPageId, limit: reviewsCount },
	})
	const { data, fetching, error } = result
	useEffect(() => {
		if (initialFetch && !fetching && !error) {
			setInitialFetch(false)
		}
	}, [initialFetch, fetching, error])
	if (initialFetch && fetching) return <LoadContainer error={error} fetching={fetching} />
	if (error) return <LoadContainer error={error} fetching={fetching} />
	const reviews = data.hostPage.hostPageReviewData
	return (
		<ReviewList
			reviews={reviews}
			reviewsCount={reviewsCount}
			setReviewsCount={setReviewsCount}
			fetching={fetching}
			displayCourse
			{...rest}
		/>
	)
}
export const CourseReviewList = ({ courseId, ...rest }) => {
	const [reviewsCount, setReviewsCount] = useState(4)
	const [initialFetch, setInitialFetch] = useState(true)
	const [result] = useQuery({
		query: getCourseReviews,
		variables: { id: courseId, limit: reviewsCount },
	})
	const { data, fetching, error } = result
	useEffect(() => {
		if (initialFetch && !fetching && !error) {
			setInitialFetch(false)
		}
	}, [initialFetch, fetching, error])
	if (initialFetch && fetching) return <LoadContainer error={error} fetching={fetching} />
	if (error) return <LoadContainer error={error} fetching={fetching} />
	const reviews = data.course.reviewData
	return (
		<ReviewList
			reviews={reviews}
			reviewsCount={reviewsCount}
			setReviewsCount={setReviewsCount}
			fetching={fetching}
			{...rest}
		/>
	)
}
