import React, { useContext } from 'react'
import UserContext from '../../../contexts/user/UserContext'

// Gives the children the current user, updated by the UserContext
const UserConsumer = props => {
	const { userState, dispatch } = useContext(UserContext)
	const child = props.children
	return React.cloneElement(child, { ...props, user: userState.user, userDispatch: dispatch }, child.props.children)
}

export default UserConsumer
