import React from 'react'
import SvgShell from './SvgShell'

const Out = props => (
	<>
		<SvgShell {...props}>
			<svg data-hide-from-admin-menu xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
				<path
					style={{ fillRule: 'evenodd' }}
					d="M1.6,0C.7,0,0,.7,0,1.6V19.2c0,.9,.7,1.6,1.6,1.6H13.6c.4,0,.8-.4,.8-.8s-.4-.8-.8-.8H1.6V1.6H13.6c.4,0,.8-.4,.8-.8s-.4-.8-.8-.8H1.6Zm15.4,6.2c-.3-.3-.8-.3-1.1,0-.3,.3-.3,.8,0,1.1l2.2,2.2H7.2c-.4,0-.8,.4-.8,.8s.4,.8,.8,.8h10.9l-2.2,2.2c-.3,.3-.3,.8,0,1.1,.3,.3,.8,.3,1.1,0l3.6-3.6c.3-.3,.3-.8,0-1.1l-3.6-3.6Z"
				/>
			</svg>
		</SvgShell>
	</>
)

export default Out
