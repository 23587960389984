import styled from 'styled-components'
import { ColoredContainer, PageSidePaddingWrapper, StyledSmallPageSectionRow } from '../layout/PageComponents'
import { theme } from '../../../utils/theme'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next-new'
import LinkButton from '../buttons/LinkButton'
import { Markdown } from '../Typography'

const StyledFaq = styled.div``
const FaqContainer = styled(StyledSmallPageSectionRow)`
	button {
		text-align: left;
	}
	${StyledFaq} {
		margin-top: 20px;
		margin-bottom: 20px;
		:first-child {
			margin-top: 0;
		}
		:last-child {
			margin-bottom: 0;
		}
	}
`
const Answer = styled.div`
	margin-top: ${theme.margins.XXSmall};
	transition: height 0.5s;
	overflow: hidden;
`

const FaqSection = ({ children, title }) => {
	const { t } = useTranslation()
	return (
		<ColoredContainer>
			<PageSidePaddingWrapper>
				<h2>{title || t('copy:faqSection.title')}</h2>
				<FaqContainer maxWidth="650px">{children}</FaqContainer>
			</PageSidePaddingWrapper>
		</ColoredContainer>
	)
}

export default FaqSection

export const Faq = ({ id, question, answer, setToggleFaq }) => {
	const [displayAnswer, setDisplayAnswer] = useState(false)
	const answerRef = useRef(null)
	// A setToggleFaq function can be passed down if we want to toggle the FAQ from a parent, e.g. setToggleFaq={setDisplayAnswer => (toggleFaq = setDisplayAnswer)}
	if (setToggleFaq) setToggleFaq(setDisplayAnswer)
	useEffect(() => {
		answerRef.current.style.height = (displayAnswer ? answerRef.current.scrollHeight : 0) + 'px'
	}, [displayAnswer])
	return (
		<StyledFaq id={id}>
			<LinkButton onClick={() => setDisplayAnswer(!displayAnswer)}>{question}</LinkButton>
			<Answer ref={answerRef}>
				<Markdown linkTarget="_blank">{answer}</Markdown>
			</Answer>
		</StyledFaq>
	)
}
