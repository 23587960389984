import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest'
import axios from 'axios'
import Close from '../svg_icons/Close'
import Search from '../svg_icons/Search'
import styled from 'styled-components'
import { limitRows } from '../PageMixins'
import { theme } from '../../../utils/theme'
import { P2 } from '../Typography'
import { getI18n } from 'react-i18next-new'
import LinkButton from '../buttons/LinkButton'
import {
	getAffiliate,
	getMarketplace,
	isMarketplaceDomain,
	isOnMarketplacePage,
} from '../../../utils/applicationHelper'
import { lookForMatchingFilterInQuery } from '../../../contexts/filter/FilterProvider'
import { disableBodyScroll, enableBodyScroll } from '../../../utils/disableBodyScroll'

const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100px;
	& > * {
		display: block;
		opacity: ${({ searchIsOpen }) => (searchIsOpen ? 1 : 0)};
		transition: opacity 0.3s;
	}
`
const InputWrapper = styled.div`
	position: relative;
	${theme.breakpoints.tablet.upToAndIncluding} {
		padding-left: ${theme.paddings.small};
		padding-right: ${theme.paddings.small};
	}
	z-index: 3;
`
const SearchIcon = styled.div`
	position: absolute;
	left: 0;
	bottom: 9px;
	cursor: pointer;
	& > svg {
		width: 18px;
		height: 18px;
	}
`
// CloseIcon has big width and height to get a bigger hit box for its onClick
const CloseIcon = styled.div`
	width: 40px;
	height: 40px;
	align-items: center;
	display: flex;
	justify-content: center;
	position: absolute;
	right: -7px;
	bottom: 3px;
	cursor: pointer;
	& > svg {
		width: 14px;
		height: 14px;
	}
`
const SuggestionContent = styled.div`
	display: flex;
	img {
		border-radius: 2px;
		width: 72px;
		min-width: 72px;
		height: 48px;
		min-height: 48px;
	}
`
const SuggestionTextContainer = styled.div`
	margin-left: ${theme.margins.XSmall};
	p:first-child {
		${limitRows(2)}
	}
	p:last-child {
		${limitRows(1)}
	}
`

function escapeRegexCharacters(str) {
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

function getSuggestions(value) {
	const escapedValue = escapeRegexCharacters(value.trim())

	if (escapedValue === '') {
		return []
	}

	return escapedValue
}

function getSuggestionValue(suggestion) {
	if (!suggestion) return ''
	return `${suggestion.name}`
}

function renderSuggestion(suggestion, { query }) {
	if (suggestion.section === 'COURSES_PAGE_LINK') return <LinkButton>{suggestion.subtitle}</LinkButton>
	if (suggestion.section === 'NO_RESULTS')
		return (
			<div style={{ paddingTop: theme.paddings.XXSmall }}>
				<p>{suggestion.title}</p>
				{/*<LinkButton>{suggestion.subtitle}</LinkButton>*/}
			</div>
		)
	return (
		<SuggestionContent>
			{suggestion.picture && <img src={suggestion.picture} />}
			<SuggestionTextContainer>
				<P2>{suggestion.name}</P2>
				<p>{suggestion.subtitle}</p>
			</SuggestionTextContainer>
		</SuggestionContent>
	)
}

// Activate for multi section search when we have added expert workshops
function renderSectionTitle(section) {
	return <div>{section.section}</div>
}

function getSectionSuggestions(section) {
	return section ? section.suggestions : []
}

function shouldRenderSuggestions(value) {
	return value.trim().length > 1
}
let bodyIsDisabled = false
let searchResultsContainer
class BookingsSearch extends Component {
	constructor(props) {
		super(props)
		const initialSuggestions = [{ suggestions: [] }, { suggestions: [] }, { suggestions: [] }, { suggestions: [] }]
		this.state = {
			value: '',
			initialSuggestions,
			suggestions: initialSuggestions,
			placeholder: props.t('shared:searchBar.placeHolder'),
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.searchIsOpen && this.props.searchIsOpen) {
			// Focus the search input after it opens so the user can type instantly without needing to click it
			this.focusInput()
		}
	}
	componentDidMount() {
		searchResultsContainer = document.getElementById('react-autowhatever-1')
	}

	onChange = (event, { newValue, method }) => {
		this.setState({
			value: newValue,
			typedValue: method === 'type' ? newValue : this.state.typedValue, // The value typed by the user, and not the value from selecting a search result with the keyboard up/down arrows (e.g. a course title)
		})
	}

	onKeyDown = event => {
		const { value, loading, noMatches } = this.state
		if (event?.keyCode === 13 && !this.suggestionIsHighlighted) {
			// The user pressed enter so search for the query, but not if a search result is already highlighted,
			// because then that search result will be searched for instead.
			if (isMarketplaceDomain()) this.navigateToFilter(value)
		} else if (event?.keyCode === 13 && !loading && noMatches) {
			// If the user presses enter when the "No search results" message is being shown, the search results dropdown will close for some reason.
			// The only way I was able to show it again was to fetch the search results again
			this.fetchResults(value)
		} else if (event?.keyCode === 27 && bodyIsDisabled) {
			enableBodyScroll(searchResultsContainer)
			bodyIsDisabled = false
		}
	}

	navigateToFilter = query => {
		this.unFocusInput()
		let subCategoryIdFoundFromQuery = false,
			skillTagIdFoundFromQuery = false
		if (window.marketplacePageFilterState) {
			subCategoryIdFoundFromQuery = lookForMatchingFilterInQuery(window.marketplacePageFilterState.subCategories, query)
		}
		if (window.marketplacePageFilterState && !subCategoryIdFoundFromQuery) {
			skillTagIdFoundFromQuery = lookForMatchingFilterInQuery(window.marketplacePageFilterState.skillTags, query)
		}
		if (subCategoryIdFoundFromQuery) {
			// The search was for a sub category, so go to the sub category page
			this.props.history.push(subCategoryIdFoundFromQuery.path)
		} else if (skillTagIdFoundFromQuery) {
			// The search was for a skill tag, so go to the skill tag's sub category page and filter on the skill tag
			let pathname
			for (let subCategory of window.marketplacePageFilterState.subCategories) {
				if (subCategory.id === skillTagIdFoundFromQuery.subCategoryId) {
					pathname = subCategory.path
					break
				}
			}
			const url = new URL(location.origin + pathname)
			url.searchParams.append('st[]', skillTagIdFoundFromQuery.id)
			this.props.history.push(url.pathname + url.search)
		} else {
			if (!isOnMarketplacePage()) {
				// The search bar is not on the MarketplacePage, so go there with the query as a parameter
				const url = new URL(getMarketplace().url)
				url.searchParams.append('q', encodeURIComponent(query))
				this.props.history.push(url.pathname + url.search)
			} else {
				// The search bar is on the MarketplacePage, so search for the query
				window.marketplacePageDispatch({ type: 'SEARCH_QUERY', data: query })
			}
		}
	}

	onSuggestionHighlighted = ({ suggestion }) => {
		// DON'T CALL setState HERE AS IT WILL PRODUCE A JS ERROR SOMETIMES (TOO MANY RENDERS) SINCE THIS CALLBACK IS CALLED FROM COMPONENT DID UPDATE
		this.suggestionIsHighlighted = !!suggestion
	}

	onSuggestionsFetchRequested = ({ value, reason }) => {
		if (shouldRenderSuggestions(value)) {
			window.clearTimeout(this.searchTimeout)
			if (!bodyIsDisabled) {
				disableBodyScroll(searchResultsContainer)
				bodyIsDisabled = true
			}
			if (reason === 'input-changed') {
				// The user typed something, so wait a little bit before fetching results in case they aren't finished typing
				// Otherwise we will fetch for each new letter which is unnecessary
				this.setState({ loading: true })
				this.searchTimeout = window.setTimeout(() => this.fetchResults(value), 400)
			} else {
				// The user most likely clicked the search bar so display the results straight away
				this.fetchResults(value)
			}
		}
	}

	fetchResults = value => {
		// Reset the height before every fetch so we can calculate if we should add scroll to search results dropdown
		searchResultsContainer.style.height = 'unset'
		const query = getSuggestions(value)
		axios({
			method: 'get',
			url: '/api/v1/public/search',
			params: {
				query,
				type: 'filter',
				locale: getI18n().language,
				affiliate: getAffiliate(),
			},
		}).then(response => {
			const results = response.data.suggestions
			const noMatchesResult = results[3]
			const noMatches = noMatchesResult?.noMatches
			let suggestions = []
			if (noMatches) {
				suggestions = noMatchesResult.suggestions
			} else {
				suggestions = results
				// Remove the "No search results found" suggestions
				suggestions.pop()
			}
			this.setState({ suggestions, noMatches, loading: false }, () => {
				// Set a fixed height of search results dropdown so it can be scrolled if it overflows the screen
				if (
					window.innerHeight <
					searchResultsContainer.getBoundingClientRect().height + searchResultsContainer.getBoundingClientRect().top
				) {
					searchResultsContainer.style.height =
						window.innerHeight - searchResultsContainer.getBoundingClientRect().top + 'px'
				}
			})
		})
	}

	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: this.state.initialSuggestions,
			noMatches: false,
		})
	}

	onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
		this.setState(
			{
				value: suggestion.name,
				typedValue: suggestion.name,
			},
			this.unFocusInput,
		)
		const { history } = this.props
		if (bodyIsDisabled) {
			enableBodyScroll(searchResultsContainer)
			bodyIsDisabled = false
		}
		if (suggestion.section === 'SUB_CATEGORY') {
			// The search suggestion was a sub category, so go to the sub category page
			if (suggestion.url.includes('https://')) {
				location = suggestion.url
			} else {
				history.push(suggestion.url)
			}
		} else if (suggestion.section === 'NO_HOST_PAGE') {
			// The search suggestion was an expert without an expert page
			// So search for the expert as a search query
			if (suggestion.url.includes('https://')) {
				location = suggestion.url
			} else {
				this.navigateToFilter(suggestion.name)
			}
		} else if (suggestion.section === 'HOST_PAGE') {
			// The search suggestion is an expert with a host page, so take the user to it
			location = suggestion.url
		} else if (suggestion.section === 'COURSE') {
			// The search suggestion is a course, so take the user to the course page
			if (suggestion.url.includes('https://')) {
				location = suggestion.url
			} else {
				history.push(suggestion.url)
			}
		} else if (suggestion.section === 'COURSES_PAGE_LINK') {
			// The search suggestion is the "See all workshops" link, so navigate to the courses page with their search value as a filter query
			if (suggestion.url.includes('https://')) {
				location = suggestion.url
			} else {
				this.navigateToFilter(this.state.value)
			}
		} else if (suggestion.section === 'NO_RESULTS') {
			// The search suggestion is the "No results match your search" link, so navigate to the Most Popular collection page
			// history.push(suggestion.url)
		}
	}

	// The user either chose a search results suggestion or clicked enter to search, so close the search bar and clear the search timer
	unFocusInput = () => {
		window.clearTimeout(this.searchTimeout)
		// Unfocus the input so the search results remain hidden after a search has been made.
		document.getElementById('AUTOSUGGEST_INPUT').blur()
		this.props.setSearchIsOpen(false)
	}

	focusInput = () => {
		document.getElementById('AUTOSUGGEST_INPUT').focus()
	}

	render() {
		const { value, suggestions, placeholder, noMatches } = this.state
		const inputProps = {
			placeholder: placeholder,
			onKeyDown: this.onKeyDown,
			onBlur: () => {
				if (bodyIsDisabled) {
					enableBodyScroll(searchResultsContainer)
					bodyIsDisabled = false
				}
				window.clearTimeout(this.searchTimeout)
			},
			value,
			type: 'search',
			onChange: this.onChange,
			id: 'AUTOSUGGEST_INPUT',
		}

		// Remove right padding on input element when no input is entered by user, so all of the placeholder text has room to be shown.
		// When input is entered however we need right padding so the input text entered by the user doesn't end up behind the close button.
		const renderInputComponent = inputProps => {
			return (
				<InputWrapper>
					<Container searchIsOpen={this.props.searchIsOpen}>
						<input {...inputProps} style={{ paddingRight: value.length > 0 ? theme.paddings.large : 0 }} />
						<SearchIcon onClick={() => value.length > 0 && this.navigateToFilter(value)}>
							<Search />
						</SearchIcon>
						{value.length > 0 && (
							<CloseIcon
								onClick={() => {
									this.setState({ value: '' })
									this.focusInput()
									if (bodyIsDisabled) {
										enableBodyScroll(searchResultsContainer)
										bodyIsDisabled = false
									}
								}}
							>
								<Close />
							</CloseIcon>
						)}
					</Container>
				</InputWrapper>
			)
		}
		return (
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				inputProps={inputProps}
				renderInputComponent={renderInputComponent}
				onSuggestionSelected={this.onSuggestionSelected}
				onSuggestionHighlighted={this.onSuggestionHighlighted}
				multiSection={!noMatches}
				renderSectionTitle={renderSectionTitle}
				getSectionSuggestions={getSectionSuggestions}
				shouldRenderSuggestions={shouldRenderSuggestions}
			/>
		)
	}
}

export default BookingsSearch
