import React, { useReducer } from 'react'
import GroupInquiryContext from './GroupInquiryContext'

const initialState = {
	displayModal: false,
	minParticipants: 4,
	courseId: null,
	hostId: null,
	modalContent: null,
	hostName: null,
}

const GroupInquiryProvider = ({ children }) => {
	const reducer = (state, { type, data }) => {
		switch (type) {
			case 'OPEN_MODAL':
				return {
					...state,
					...data,
					displayModal: true,
				}
			case 'CLOSE_MODAL':
				return {
					...state,
					...initialState, // Reset the state upon close. Therefore all props needs to be in initialState
					displayModal: false,
				}
			default:
				throw new Error()
		}
	}
	const [state, dispatch] = useReducer(reducer, { ...initialState })
	return <GroupInquiryContext.Provider value={{ state, dispatch }}>{children}</GroupInquiryContext.Provider>
}

export default GroupInquiryProvider
