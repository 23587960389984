import React, { useContext } from 'react'
import NonResponsiveImage from '../responsive/NonResponsiveImage'
import { P4, P5, TextSpan } from '../Typography'
import Button from '../buttons/Button'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'
import { useTranslation } from 'react-i18next-new'
import ContentSideBarContext from '../../../contexts/content_side_bar/ContentSideBarContext'
import { DynamicPageSection, SmallPageSectionDivider } from '../layout/PageComponents'

const DateRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${theme.margins.small};
	> *:nth-child(1) {
		${theme.breakpoints.tablet.upToAndIncluding} {
			min-width: 90px;
		}
		${theme.breakpoints.tablet.overAndExcluding} {
			width: 15%;
		}
	}
	> *:nth-child(2) {
		${theme.breakpoints.tablet.upToAndIncluding} {
			width: 60%;
			> div {
				display: flex;
				padding-right: ${theme.paddings.XXSmall};
				justify-content: center;
				> div {
					display: flex;
					flex-direction: column;
					align-items: center;
					text-align: center;
					> div:first-child {
						display: flex;
						flex-direction: row-reverse;
						flex-wrap: wrap;
						justify-content: center;
						> p:last-child {
							margin-right: ${theme.margins.XSmall};
						}
					}
				}
			}
		}
		${theme.breakpoints.tablet.overAndExcluding} {
			width: 75%;
			> div > div {
				display: flex;
				justify-content: space-between;
				> *:first-child {
					display: flex;
					width: 30%;
					justify-content: space-between;
					> *:first-child {
						flex: 0.6;
					}
					> *:last-child {
						margin-left: ${theme.margins.XSmall};
						flex: 0.4;
					}
				}
				> *:nth-child(2) {
					width: 10%;
					> span {
						white-space: nowrap;
					}
				}
				> *:nth-child(3) {
					width: 20%;
				}
				> *:last-child {
					width: 30%;
				}
			}
		}
	}
	> *:nth-child(3) {
		text-align: end;
		${theme.breakpoints.tablet.upToAndIncluding} {
			min-width: 72px;
		}
		${theme.breakpoints.tablet.overAndExcluding} {
			width: 10%;
		}
	}
`
const CourseDateImage = styled.div`
	position: relative;
	width: 70px;
	height: 70px;
	* {
		border-radius: 100%;
	}
	> p {
		position: absolute;
		background: ${theme.colors.black};
		color: white;
		top: 0;
		right: 0;
		width: 45px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		white-space: nowrap;
		transform: translate(40%, -40%);
	}
`

const CourseDateCard = ({ course, courseDate, includeTitle }) => {
	const { t } = useTranslation()
	const { numberOfConsecutiveCourseDates, duration, physical, province, price, image, title, reviewData } = course
	const { shortDate, showMaxParticipants, seatsLeft, maxParticipants, full, url, startsAtTime } = courseDate
	const { state, dispatch } = useContext(ContentSideBarContext)
	const openCoursePage = event => {
		// The card is an a tag link so it can be indexed by Google, but we want the overlay to open instead of
		// the user being rerouted to the course page, so prevent the default of the a tag.
		event.preventDefault()
		dispatch({ type: 'NEW_CONTENT', data: { content: course, contentType: 'COURSE' } })
	}
	return (
		<div>
			{includeTitle && (
				<DynamicPageSection margins={{ bottom: theme.margins.largeInt }}>
					<a href={course.url} onClick={openCoursePage}>
						<p>
							{title}&nbsp;&nbsp;&nbsp;
							{reviewData.count > 0 ? (
								<>
									<TextSpan textColor="orange">★&nbsp;</TextSpan>
									<TextSpan textColor="lightTextColor">
										{reviewData.rating} ({reviewData.count} {t('copy:coursePage.reviews')})
									</TextSpan>
								</>
							) : (
								<TextSpan textColor="lightTextColor">{t('common:workshop.new')}</TextSpan>
							)}
						</p>
					</a>
				</DynamicPageSection>
			)}
			<DateRow>
				<div>
					<a href={course.url} onClick={openCoursePage}>
						<CourseDateImage>
							<NonResponsiveImage imagePath={image} width={70} type="ROUND" alt={title} />
							<P5>{shortDate}</P5>
						</CourseDateImage>
					</a>
				</div>
				<a href={course.url} onClick={openCoursePage}>
					<div>
						<div>
							<div>
								<P4 textColor={full ? 'lightTextColor' : 'textColor'}>
									{price ? <span>{price}&nbsp;kr/person</span> : t('common:payments.free')}
								</P4>
								{(numberOfConsecutiveCourseDates > 1 && (
									<P4 textColor={full ? 'lightTextColor' : 'textColor'}>
										{numberOfConsecutiveCourseDates}&nbsp;
										{t('common:workshop.session', { count: numberOfConsecutiveCourseDates })}
									</P4>
								)) || <P4 textColor={full ? 'lightTextColor' : 'textColor'}>{duration}</P4>}
							</div>
							<P4 textColor={full ? 'lightTextColor' : 'textColor'}>
								{startsAtTime}&nbsp;<TextSpan textColor="lightTextColor">CEST</TextSpan>&nbsp;
							</P4>
							<P4>{physical ? province : 'Online'}</P4>
							<P4 textColor={full ? 'lightTextColor' : 'textColor'}>
								{showMaxParticipants
									? t('copy:coursePage.seatsLeftOutOf', { seatsLeft, maxParticipants })
									: t('copy:coursePage.seatsLeft', { seatsLeft })}
							</P4>
						</div>
					</div>
				</a>
				{full ? (
					<P4 textColor="lightTextColor">{t('common:workshop.full')}</P4>
				) : (
					<a href={url}>
						<Button>{t('common:buttons.book')}</Button>
					</a>
				)}
			</DateRow>
			{includeTitle && <SmallPageSectionDivider color={theme.colors.darkGray} />}
		</div>
	)
}

export default CourseDateCard
