import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'

const ReviewStar = styled.span`
	margin-right: 2px;
	color: ${({ filled }) => (filled ? theme.colors.orange : theme.colors.lightTextColor)};
`
const Rating = ({ rating }) => {
	let stars = []
	for (let i = 0; i < rating; i++) {
		stars.push(
			<ReviewStar filled key={i}>
				★
			</ReviewStar>,
		)
	}
	for (let i = rating; i < 5; i++) {
		stars.push(<ReviewStar key={i}>★</ReviewStar>)
	}
	return stars
}

export default Rating
