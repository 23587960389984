import axios from 'axios'
import { CenteredSmallPageSection, SmallPageSection } from '../../layout/PageComponents'
import Button from '../../buttons/Button'
import Form from '../../forms/Form'
import TextInput from '../../forms/TextInput'
import { P2 } from '../../Typography'
import React, { useState } from 'react'
import useRoutes from '../../../../hooks/useRoutes'

const ForgotPasswordContent = ({ dispatch, t, onLogin, ...contextData }) => {
	const [submitted, setSubmitted] = useState(false)
	const routes = useRoutes()
	const handleSubmit = (inputValues, onSubmitFinished) => {
		const { email } = inputValues
		axios({
			method: 'post',
			url: routes.api.v1.auth.resetPassword.url,
			data: {
				email,
			},
		})
			.then(response => {
				if (response.data.success === true) {
					setSubmitted(true)
				} else {
					onSubmitFinished(t('common:errormessages.generic'))
				}
			})
			.catch(error => {
				if (error.response) {
					console.error('error.response:', error.response)
					onSubmitFinished(error.response.data.errorMessage || t('common:errormessages.generic'))
				}
			})
	}
	return (
		<>
			<CenteredSmallPageSection>
				{submitted ? (
					<>
						<P2>{t('copy:sessionModal.resetPwSentTitle')}</P2>
						<SmallPageSection>{t('copy:sessionModal.resetPwSentMessage')}</SmallPageSection>
					</>
				) : (
					<>
						<P2>{t('copy:sessionModal.resetPwTitle')}</P2>
						<SmallPageSection>{t('copy:sessionModal.resetPwText')}</SmallPageSection>
					</>
				)}
			</CenteredSmallPageSection>
			{!submitted && (
				<Form onSubmit={handleSubmit}>
					<TextInput type="email" name="email" required />
					<SmallPageSection>
						<Button buttonType="RECTANGLE" wide>
							{t('copy:sessionModal.resetPwLink')}
						</Button>
					</SmallPageSection>
				</Form>
			)}
		</>
	)
}

export default ForgotPasswordContent
