import React from 'react'
import SvgShell from './SvgShell'

const Fire = props => (
	<SvgShell {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.54 28.79">
			<path
				style={{
					fill: 'currentColor',
					fillRule: 'evenodd',
				}}
				d="M14.88,2.14a1.05,1.05,0,0,1,1-.34C21,3,23.62,8.57,23,13.59a10,10,0,0,1-1.68,4.69v0c.19-.1.37-.2.54-.31a10.56,10.56,0,0,0,2.26-2.2l.3-.35a1.06,1.06,0,0,1,1.75.22,10.27,10.27,0,1,1-14.8-4l.09-.1.16-.13a10.63,10.63,0,0,0,2.7-2.29,4.81,4.81,0,0,0,.91-3.06,8.08,8.08,0,0,0-.54-2.91A1.07,1.07,0,0,1,14.88,2.14Zm2.36,2.47a9.42,9.42,0,0,1,.11,1.51A6.93,6.93,0,0,1,16,10.45a12.35,12.35,0,0,1-3.13,2.72l-.1.11a1.33,1.33,0,0,1-.19.14,8.15,8.15,0,1,0,12.54,6.87,8.29,8.29,0,0,0-.28-2.13A9.6,9.6,0,0,1,23,19.77a9.34,9.34,0,0,1-5.31,1.34,1.07,1.07,0,0,1-.51-2,6.92,6.92,0,0,0,2.4-2.06,7.94,7.94,0,0,0,1.3-3.72C21.34,9.75,19.85,6.21,17.24,4.61Z"
				transform="translate(-6.73 -1.77)"
			/>
		</svg>
	</SvgShell>
)

export default Fire
