// Displays a box with a link to end the impersonation
import axios from 'axios'
import useRoutes from '../../../hooks/useRoutes'
import React from 'react'
import getCsrfToken from '../../../hooks/getCsrfToken'
import { SmallPageSectionRepulsiveRow } from '../../reusable_components/layout/PageComponents'
import CrocodileRight from '../../reusable_components/svg_icons/CrocodileRight'

const Impersonation = () => {
	const routes = useRoutes()
	const csrfToken = getCsrfToken()
	const onClick = () => {
		axios({
			method: 'delete',
			url: routes.superAdmin.impersonation.url,
			headers: { 'X-CSRF-Token': csrfToken },
		}).then(response => (location = response.data.redirectUrl))
	}
	return (
		<SmallPageSectionRepulsiveRow style={{ cursor: 'pointer' }} onClick={onClick}>
			Back to SA
			<CrocodileRight />
		</SmallPageSectionRepulsiveRow>
	)
}

export default Impersonation
