import React from 'react'
import styled from 'styled-components'
import { P2, P4 } from '../Typography'
import ResponsiveImage from '../responsive/ResponsiveImage'
import { SmallPageSection } from '../layout/PageComponents'
import { limitRows } from '../PageMixins'
import ContentSideBarContext from '../../../contexts/content_side_bar/ContentSideBarContext'
import PropTypes from 'prop-types'
import { CourseCardWrapper } from './CourseCard'
import { theme } from '../../../utils/theme'

const Title = styled(P2)`
	margin-top: ${theme.margins.small};
	${limitRows(3)}
	width: 90%;
`
const Summary = styled(P4)`
	${limitRows(4)}
	width: 90%;
`

const HostCard = ({ host }) => {
	const { url, hostPicture, fullName, expertType, description } = host
	return (
		<ContentSideBarContext.Consumer>
			{({ state, dispatch }) => {
				return (
					<CourseCardWrapper>
						<a
							href={url}
							// Disabled because we don't allow host page to be opened in sidebar anymore, unless it is a preview
							/*onClick={event => {
								// The card is an a tag link so it can be indexed by Google, but we want the overlay to open instead of
								// the user being rerouted to the host page, so prevent the default of the a tag.
								event.preventDefault()
								dispatch({ type: 'NEW_CONTENT', data: { content: host, contentType: 'HOST' } })
							}}*/
						>
							<ResponsiveImage
								imagePath={hostPicture}
								type="BIG_VERTICAL_RECTANGLE"
								alt={`${fullName} - ${expertType}`}
								sourceWidth={theme.breakpoints.phone.maxInt}
							/>
							<Title>{fullName}</Title>
							<SmallPageSection>
								<P4 textColor="lightTextColor">{expertType}</P4>
							</SmallPageSection>
							<SmallPageSection>
								<Summary>{description}</Summary>
							</SmallPageSection>
						</a>
					</CourseCardWrapper>
				)
			}}
		</ContentSideBarContext.Consumer>
	)
}

HostCard.propTypes = {
	host: PropTypes.object,
}

export default HostCard
