import React from 'react'
import { PaymentElement } from '@stripe/react-stripe-js'
import { SmallPageSection } from '../../reusable_components/layout/PageComponents'

class CreditCardSection extends React.PureComponent {
	render() {
		return (
			<SmallPageSection id="creditCardParent">
				<PaymentElement
					onChange={this.props.onChange}
					onReady={this.props.onReady}
					id="creditCard"
					options={{
						fields: {
							billingDetails: {
								/* This is already collected in stripe.confirmPayment on CheckoutPage, so prohibit Stripe from also collecting it here */
								name: 'never',
								email: 'never',
								phone: 'never',
							},
						},
					}}
				/>
			</SmallPageSection>
		)
	}
}

export default CreditCardSection
