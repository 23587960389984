import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { loadableReady } from '@loadable/component'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import AppStructure from './components/layout/AppStructure'
import 'swiped-events'
import { ssrExchange } from 'urql'
import { setCsrfToken } from './hooks/getCsrfToken'

const Jsx = props => {
	const { railsContext } = props
	return (
		<App {...props} railsContext={railsContext}>
			<BrowserRouter>
				<AppStructure {...props} />
			</BrowserRouter>
		</App>
	)
}

const AppClient = (props, railsContext, domId) => {
	delete props.ssrOnlyProps
	setCsrfToken(document.getElementsByName('csrf-token')[0].content)
	const ssr = ssrExchange({
		isClient: true,
		initialState: window.__URQL_DATA__,
	})
	const jsx = <Jsx {...props} railsContext={railsContext} urqlSsr={ssr} />
	const root = document.getElementById(domId)
	// Don't use code splitting on dev since it doesn't support HMR
	if (props.isDevServer) {
		hydrateRoot(root, jsx)
	} else {
		loadableReady(() => {
			hydrateRoot(root, jsx)
		})
	}
}

export default AppClient
