import React from 'react'
import styled from 'styled-components'
import { theme } from '../../utils/theme'

import {
	CenteredDynamicPageSection,
	ContentRow,
	Divider,
	DynamicPageSection,
	PageTopSection,
} from '../reusable_components/layout/PageComponents'
import { Markdown, P2 } from '../reusable_components/Typography'
import Button from '../reusable_components/buttons/Button'
import FaqSection, { Faq } from '../reusable_components/other/FaqComponents'
import useRoutes from '../../hooks/useRoutes'
import HeaderImage from '../reusable_components/other/HeaderImage'
import CourseCardsWrapper from '../reusable_components/course_cards/CourseCardsWrapper'

export const VideoFormContainer = styled.div`
	display: flex;
	iframe {
		max-width: 100%;
		flex: 1;
		border: 0;
		min-height: 200px;
		${theme.breakpoints.tablet.range} {
			min-height: 350px;
		}
		${theme.breakpoints.tablet.overAndExcluding} {
			min-height: 300px;
		}
	}
`
const ExpertInfo = ({ t }) => {
	const routes = useRoutes()
	return (
		<>
			<PageTopSection>
				<h1>{t('copy:expertInfoPage.pageTitle')}</h1>
				<P2>{t('copy:expertInfoPage.pageSubTitle')}</P2>
			</PageTopSection>
			<HeaderImage
				desktop="static_assets/listworkshop/petra_lund_table_people"
				mobile="static_assets/listworkshop/petra_lund_table_people-mobile"
			/>
			<ContentRow>
				<h2>{t('copy:expertInfoPage.firstH2')}</h2>
				<Markdown>{t('copy:expertInfoPage.firstParagraph')}</Markdown>
			</ContentRow>
			<Divider />
			<ContentRow>
				<VideoFormContainer>
					<iframe src="https://www.youtube.com/embed/I39V8VkbJxU" />
				</VideoFormContainer>
				<Markdown>{t('copy:expertInfoPage.secondParagraph')}</Markdown>
			</ContentRow>
			<DynamicPageSection margins={{ bottom: theme.margins.smallInt }}>
				<Divider />
			</DynamicPageSection>
			<h2>{t('copy:expertInfoPage.getStartedTitle')}</h2>
			<ContentRow>
				<p>{t('copy:expertInfoPage.getStartedText1')}</p>
				<p>{t('copy:expertInfoPage.getStartedText2')}</p>
				<p>{t('copy:expertInfoPage.getStartedText3')}</p>
			</ContentRow>
			<CenteredDynamicPageSection margins={{ all: theme.margins.largeInt }}>
				<h1>{t('copy:expertInfoPage.makeMoneyHeadline')}</h1>
				<P2>{t('copy:expertInfoPage.makeMoneyText')}</P2>
				<br />
				<br />
				<a href={routes.signup.expertApply.url}>
					<Button>{t('common:buttons.applyFor')}</Button>
				</a>
			</CenteredDynamicPageSection>
			<Divider />
			<CourseCardsWrapper
				title={t('copy:expertInfoPage.meetExpertsTitle')}
				subtitle={t('copy:expertInfoPage.meetExpertsSubtitle')}
				type="specific_host_pages"
				id={[191, 3848, 475, 609, 1381, 1015]}
				optimizeRows
				lazyLoad
			/>
			<FaqSection>
				<div>
					<Faq question={t('copy:expertInfoPage.faqQuestion1')} answer={t('copy:expertInfoPage.faqAnswer1')} />
					<Faq question={t('copy:expertInfoPage.faqQuestion2')} answer={t('copy:expertInfoPage.faqAnswer2')} />
				</div>
				<div>
					<Faq question={t('copy:expertInfoPage.faqQuestion3')} answer={t('copy:expertInfoPage.faqAnswer3')} />
					<Faq question={t('copy:expertInfoPage.faqQuestion4')} answer={t('copy:expertInfoPage.faqAnswer4')} />
				</div>
			</FaqSection>
		</>
	)
}

export default ExpertInfo
