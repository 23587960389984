import React, { useState } from 'react'
import { CenteredSection, ContentRow } from '../../reusable_components/layout/PageComponents'
import { theme } from '../../../utils/theme'
import BookableDates, { SeeAllDatesButton } from './BookableDates'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import PurchaseFooter from './PurchaseFooter'
import GroupBookingAndGiftCardRow from './GroupBookingAndGiftCardRow'
import EnvelopeFollowButton from '../../reusable_components/buttons/EnvelopeFollowButton'

const PurchaseSection = props => {
	const { bookableDates = [], id, firstAvailableDate, t, price } = props
	const [displayDates, setDisplayDates] = useState(false)
	let title
	if (firstAvailableDate) {
		// Dates are available to book
		if (bookableDates.length === 1) {
			// Only one date is available to book
			title = t('copy:coursePage.chooseFromOneDate')
		} else {
			// Multiple dates are available to book
			title = t('copy:coursePage.chooseFromAvailableDates')
		}
	} else if (bookableDates.length > 0) {
		// Dates exist, but all are full
		title = t('copy:coursePage.allDatesFull')
	} else {
		// No dates exist at all
		title = t('copy:coursePage.noNewDates')
	}
	const priceText = price ? `${price} kr/person` : t('common:payments.free')
	return (
		<>
			<ContentRow
				title={title}
				childrenPerRowDesktop={3}
				alignItems="flex-start"
				margins={{ innerMobile: theme.margins.XSmallInt }}
				bottomContent={
					bookableDates.length >= 5 && (
						<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
							<CenteredSection>
								<SeeAllDatesButton t={t} setDisplayDates={setDisplayDates} />
							</CenteredSection>
						</Breakpoint>
					)
				}
				extraTitleContent={
					<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
						<EnvelopeFollowButton favouritableId={id} />
					</Breakpoint>
				}
			>
				<BookableDates {...props} priceText={priceText} displayDates={displayDates} setDisplayDates={setDisplayDates} />
				<GroupBookingAndGiftCardRow {...props} />
			</ContentRow>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
				<PurchaseFooter {...props} priceText={priceText} setDisplayDates={setDisplayDates} />
			</Breakpoint>
		</>
	)
}

export default PurchaseSection
