import React from 'react'
import styled, { keyframes } from 'styled-components'
import { theme } from '../../../utils/theme'

const loading = keyframes`
  100% {
    transform: translateX(100%);
  }
`

const LoadingPlaceholder = styled.div`
	background: ${theme.colors.white};
	overflow: hidden;
	position: relative;
	border-radius: 5px;
	box-shadow: #e7e7e7 1px 1px 4px;
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(238, 237, 237, 0.55), rgba(0, 0, 0, 0));
		transform: translateX(-100%);
		animation: ${loading} 1s infinite;
	}
`
const TitleSectionPlaceholder = styled(LoadingPlaceholder)`
	height: ${theme.lineHeights.p4};
	width: 30%;
	min-width: 150px;
`
const ImageSectionPlaceholder = styled(LoadingPlaceholder)`
	width: 70px;
	height: 70px;
	border-radius: 100%;
`

const FirstPlaceholder = styled(LoadingPlaceholder)`
	width: 50%;
	height: 40px;
`
const SecondPlaceholder = styled(LoadingPlaceholder)`
	width: 10%;
	height: 40px;
`
const PlaceholderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: ${theme.paddings.large};
	padding-top: ${theme.paddings.large};
`

const Placeholder = () => (
	<div>
		<TitleSectionPlaceholder />
		<PlaceholderWrapper>
			<ImageSectionPlaceholder />
			<FirstPlaceholder />
			<SecondPlaceholder />
		</PlaceholderWrapper>
	</div>
)

export default Placeholder
