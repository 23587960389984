import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'
import { ProfilePicture } from '../../reusable_components/other/CourseComponents'
import SideBar from '../../reusable_components/modals/content_side_bar/SideBar'
import UserMenuContent from './UserMenuContent'
import { isLocalStorageAvailable } from '../../../utils/applicationHelper'
import { BeaconAdjuster } from '../../../utils/helpScoutHelper'
import { TextSpan } from '../../reusable_components/Typography'

const UserMenuWrapper = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	> b {
		margin-right: ${theme.margins.XSmall};
		${theme.breakpoints.phone.range} {
			display: none;
		}
	}
`
// Call this function to open the user menu
export let openUserMenu

const UserMenu = ({ user, t, toolsHeader, transparentHeader, transparentHeaderTextColor }) => {
	const [menuOpen, setMenuOpen] = useState(false)
	openUserMenu = () => setMenuOpen(true)
	const closeMenuOnLinkClick = () => setMenuOpen(false)
	useEffect(() => {
		// The user menu can be requested to be shown when the user loads the page, e.g. after they create a host account to initiate their onboarding
		if (isLocalStorageAvailable() === true && localStorage.getItem('showUserMenu')) {
			openUserMenu()
			localStorage.removeItem('showUserMenu')
		}
	}, [])
	return (
		<>
			<UserMenuWrapper
				onClick={event => {
					menuOpen || setMenuOpen(true)
				}}
			>
				<b>
					<TextSpan textColor={transparentHeader ? transparentHeaderTextColor : 'textColor'}>
						{user.isHost ? user.fullName : user.firstName}
					</TextSpan>
				</b>
				<ProfilePicture width={35} profileName={user.fullName} imagePath={user.image} />
			</UserMenuWrapper>
			<SideBar isOpen={menuOpen} handleClose={() => setMenuOpen(false)} maxWidth={500} position="RIGHT" color="WHITE">
				<UserMenuContent user={user} closeMenuOnLinkClick={closeMenuOnLinkClick} toolsHeader={toolsHeader} />
			</SideBar>
			<BeaconAdjuster adjust={menuOpen} source="USER_MENU" />
		</>
	)
}

export default UserMenu
