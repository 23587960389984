import React from 'react'
import useRoutes from '../../../hooks/useRoutes'
import GroupInquiryModalTrigger from '../../reusable_components/modals/group_inquiry/GroupInquiryModalTrigger'
import { theme } from '../../../utils/theme'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import Button from '../../reusable_components/buttons/Button'
import styled from 'styled-components'
import { SmallPaddingWrapper } from '../../reusable_components/layout/PageComponents'
import Group from '../../reusable_components/svg_icons/Group'

const PurchaseOptionBox = styled(SmallPaddingWrapper)`
	background: ${({ background }) => background || theme.colors.black};
	color: ${({ background }) => (background ? theme.colors.textColor : 'white')};
	cursor: pointer;
	border-radius: 2px;
	display: flex;
	${theme.breakpoints.tablet.upToAndIncluding} {
		flex-direction: column;
		min-height: 240px;
		> div {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			text-align: center;
			:first-child {
				flex: 1.3;
			}
			:last-child {
				flex: 0.7;
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;
			}
		}
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		min-height: ${({ minHeight }) => minHeight}px;
		justify-content: space-between;
		> div {
			:first-child > p {
				display: flex;
				flex-wrap: wrap;
				gap: 5px;
				align-items: center;
				overflow-wrap: anywhere;
			}
			:last-child {
				margin-left: ${theme.margins.XSmall};
				display: flex;
				align-items: center;
			}
		}
	}
`

const StyledGroupBookingAndGiftCardRow = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	> * {
		width: calc(100%);
		/*:last-child {
			margin-left: ${theme.margins.XSmall};
		}*/
	}
	> ${PurchaseOptionBox} {
		background: ${theme.colors.gray};
		color: ${theme.colors.textColor};
	}
`

const GroupBookingAndGiftCardRow = props => (
	<>
		<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
			<StyledGroupBookingAndGiftCardRow>
				<GroupBookingAndGiftCard {...props} />
			</StyledGroupBookingAndGiftCardRow>
		</Breakpoint>
		<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
			<GroupBookingAndGiftCard {...props} />
		</Breakpoint>
	</>
)

export default GroupBookingAndGiftCardRow

const GroupBookingAndGiftCard = props => {
	const { t, minParticipants, id, price, bookableDates = [], host, numberOfConsecutiveCourseDates } = props
	const routes = useRoutes()
	let minHeight
	// The grey date boxes have different heights depending on these three scenarios, so set these black boxes' min height depending on the scenario
	if (bookableDates?.length) {
		minHeight = numberOfConsecutiveCourseDates > 1 ? 203 : 168
	} else {
		minHeight = 122
	}
	if (price === 0) return null // Don't sell gift cards or allow group bookings for free workshops
	return (
		<>
			<GroupInquiryModalTrigger hostName={host.fullName} minParticipants={minParticipants} courseId={id}>
				<PurchaseOptionBox minHeight={minHeight}>
					<div>
						<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
							<Group />
							<br />
						</Breakpoint>
						<p>
							<span>{t('copy:coursePage.bookPrivateDateGroup')}</span>
							<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
								<Group width="16px" height="16px" />
							</Breakpoint>
						</p>
					</div>
					<div>
						<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
							<Button color="BLACK">{t('common:buttons.contact')}</Button>
						</Breakpoint>
						<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
							<Button color="WHITE">{t('common:buttons.contact')}</Button>
						</Breakpoint>
					</div>
				</PurchaseOptionBox>
			</GroupInquiryModalTrigger>
			{/*<a href={bookableDates?.length > 0 ? routes.giftcard.new.url + '?course_id=' + id : routes.static.giftcard.url}>
				<PurchaseOptionBox minHeight={minHeight} style={{ height: '100%' }}>
					<div>
						<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
							<Gift />
							<br />
						</Breakpoint>
						<p>
							{t('copy:coursePage.buySkillAsGift')}
							<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
								&nbsp;&nbsp;
								<Gift width="16px" height="16px" />
							</Breakpoint>
						</p>
					</div>
					<div>
						<Button color="WHITE">{t('common:buttons.buy')}</Button>
					</div>
				</PurchaseOptionBox>
			</a>*/}
		</>
	)
}
