import { PageSection } from '../layout/PageComponents'
import Breakpoint from '../responsive/Breakpoint'
import { theme } from '../../../utils/theme'
import ResponsiveImage from '../responsive/ResponsiveImage'
import React from 'react'

const HeaderImage = ({ children, image, mobile, desktop }) => (
	<PageSection>
		<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndExcluding}>
			<ResponsiveImage
				imagePath={mobile || image}
				type="VERTICAL_RECTANGLE"
				sourceWidth={theme.breakpoints.phone.maxInt}
			>
				{children}
			</ResponsiveImage>
		</Breakpoint>
		<Breakpoint breakpoint={theme.breakpoints.tablet.range}>
			<ResponsiveImage imagePath={mobile || image} type="SQUARE" sourceWidth={theme.breakpoints.tablet.maxInt}>
				{children}
			</ResponsiveImage>
		</Breakpoint>
		<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
			<ResponsiveImage
				imagePath={desktop || image}
				type="RECTANGLE"
				sourceWidth={theme.widths.pageMaxWidthIncludingPaddingInt}
			>
				{children}
			</ResponsiveImage>
		</Breakpoint>
	</PageSection>
)

export default HeaderImage
