import React from 'react'
import SvgShell from './SvgShell'

const Gift = props => {
	const style = { fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round' }
	return (
		<SvgShell {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 23.53">
				<path
					style={style}
					d="M27,10H5a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H27a1,1,0,0,0,1-1V11A1,1,0,0,0,27,10Z"
					transform="translate(-3.5 -2.97)"
				/>
				<path style={style} d="M26,16v9a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16" transform="translate(-3.5 -2.97)" />
				<path style={style} d="M16,10V26" transform="translate(-3.5 -2.97)" />
				<path
					style={style}
					d="M21.66,8.59C20.24,10,16,10,16,10s0-4.24,1.41-5.66a3,3,0,0,1,4.25,4.25Z"
					transform="translate(-3.5 -2.97)"
				/>
				<path
					style={style}
					d="M10.34,8.59C11.76,10,16,10,16,10s0-4.24-1.41-5.66a3,3,0,0,0-4.25,4.25Z"
					transform="translate(-3.5 -2.97)"
				/>
			</svg>
		</SvgShell>
	)
}

export default Gift
