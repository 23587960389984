import React, { useState } from 'react'
import Form from '../../forms/Form'
import axios from 'axios'
import TextInput from '../../forms/TextInput'
import SelectInput from '../../forms/SelectInput'
import DateInput from '../../forms/DateInput'
import TextAreaInput from '../../forms/TextAreaInput'
import Button from '../../buttons/Button'
import { Markdown } from '../../Typography'

const GroupInquiryContent = ({ user, groupInquiryState, t }) => {
	let { minParticipants, courseId } = groupInquiryState

	const [inquirySent, setInquirySent] = useState(false)
	const { fullName, email, phone } = user
	const onSubmit = (inputValues, onSubmitFinished) => {
		const url = '/api/v1/public/team_building/new_group_inquiry'
		axios({
			method: 'post',
			url: url,
			data: {
				group_inquiry: {
					courseId: courseId,
					...inputValues,
				},
			},
		})
			.then(response => {
				setInquirySent(true)
				const dataLayer = window.dataLayer || []
				// Universal Analytics
				dataLayer.push({
					event: 'groupSubmissionComplete',
				})
				// GA4
				gtag('event', 'select_content', {
					content_type: 'group_booking_request',
					item_id: `${courseId}`,
				})
			})
			.catch(error => {
				onSubmitFinished(t('common:errormessages.generic'))
			})
	}
	return inquirySent ? (
		<>
			<h2>{t('shared:groupInquiryModal.thankYouHeadline')}</h2>
			<p>{t('shared:groupInquiryModal.thankYouBody')}</p>
		</>
	) : (
		<>
			<h2>{t('shared:groupInquiryModal.requestGroupBookingHeadline')}</h2>
			<Markdown>{t('shared:groupInquiryModal.requestGroupBookingText')}</Markdown>
			<br />
			<Form onSubmit={onSubmit}>
				<TextInput name="name" value={fullName} required />
				<TextInput name="email" value={email} type="email" required />
				<TextInput name="phone" value={phone} type="tel" required />
				<TextInput
					name="attendants"
					label={`${t('common:forms.attendants')} (${t('common:forms.minimum')} ${minParticipants})`}
					type="number"
					value={minParticipants}
					min={minParticipants}
				/>
				<SelectInput
					name="groupType"
					value="private"
					options={[
						{ key: 'private', value: t('common:forms.privateGroup') },
						{ key: 'company', value: t('common:forms.company') },
					]}
				/>
				<DateInput name="primaryDate" required />
				<SelectInput
					name="dayNight"
					value="day"
					options={[
						{ key: 'day', value: t('common:forms.day') },
						{ key: 'night', value: t('common:forms.night') },
					]}
				/>
				<TextAreaInput name="alternativeDates" />
				<SelectInput
					name="ownFacility"
					value="yes"
					options={[
						{ key: 'yes', value: t('common:forms.yes') },
						{ key: 'no', value: t('common:forms.no') },
					]}
				/>
				<TextAreaInput name="groupInquiryMessage" />
				<Button buttonType="RECTANGLE" wide>
					{t('common:buttons.send')}
				</Button>
			</Form>
		</>
	)
}

export default GroupInquiryContent
