import React, { useEffect, useRef, useState } from 'react'

// Use to load a component when user scrolls to it. Useful in e.g. ContentSideBar since LazyLoad component doesn't work there
const IntersectionObserverWrapper = props => {
	const { children, callback, defaultMountedToDom } = props
	const [mountedToDom, setMountedToDom] = useState(defaultMountedToDom)
	let ref = useRef(null)
	useEffect(() => {
		if ('IntersectionObserver' in window) {
			// Start displaying the scarcity badge when the user scrolls to it
			const observer = new IntersectionObserver((entries, observer) => {
				entries.forEach(element => {
					if (element.isIntersecting && ref.current) {
						// The user scrolled to the scarcity badge, so show it and stop observing
						setMountedToDom(true)
						observer.unobserve(ref.current)
						if (callback) callback()
					}
				})
			}, {})
			observer.observe(ref.current)
		} else {
			// IntersectionObserver is not supported by the browser, so just display the scarcity badge right away instead
			setMountedToDom(true)
			if (callback) callback()
		}
	}, [])
	return <div ref={ref}>{mountedToDom && children}</div>
}

export default IntersectionObserverWrapper
