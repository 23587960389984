import axios from 'axios'
import useRoutes from '../../../../hooks/useRoutes'
import {
	CenteredSection,
	LeftAlignedFlexBox,
	RepulsiveRow,
	SmallPageSection,
	XSmallPageSection,
} from '../../layout/PageComponents'
import Button from '../../buttons/Button'
import Form from '../../forms/Form'
import TextInput from '../../forms/TextInput'
import { P2, UnderlinedA, UnderlinedSpan } from '../../Typography'
import React from 'react'
import SocialMediaButtons from './SocialMediaButtons'
import ToggleableElement from '../../other/ToggleableElement'
import CrocodileUp from '../../svg_icons/CrocodileUp'
import CrocodileDown from '../../svg_icons/CrocodileDown'
import getWhiteLabel from '../../../../hooks/getWhiteLabel'
import PasswordInput from '../../forms/PasswordInput'
import { isMarketplace } from '../../../../utils/applicationHelper'

const LoginContent = ({ dispatch, t, onLogin, ...contextData }) => {
	const routes = useRoutes()
	let { email, message, title, tooltip, socialMediaButtonProps, initiateOnboarding, tier, pwa } = contextData
	const handleSubmit = (inputValues, onSubmitFinished) => {
		const { email, password, referral } = inputValues
		axios({
			method: 'post',
			url: routes.session.create.url,
			data: {
				email: email,
				password: password,
				used_signup_referral_code: referral,
				initiate_onboarding: initiateOnboarding, // If there is no intent of the signup/signin (e.g. book or favourite), then approve of user onboarding
				tier,
				pwa: pwa.openedInPwa,
			},
		})
			.then(response => {
				if (response.data.success === true) {
					onLogin(
						response.data.user,
						response.data.hostWantsToUpgradeTier,
						response.data.hostAccountWasCreated,
						response.data.initiateOnboarding,
					)
				} else {
					onSubmitFinished(t('common:errormessages.generic'))
				}
			})
			.catch(error => {
				if (error.response) {
					onSubmitFinished(error.response.data.errorMessage || t('common:errormessages.generic'))
				}
			})
	}
	if (!title) title = t('copy:sessionModal.signIn')
	return (
		<>
			<CenteredSection>
				{title && (
					<>
						<P2>{title}</P2>
						<XSmallPageSection />
					</>
				)}
				{message && (
					<>
						<p>{message}</p>
						<XSmallPageSection />
					</>
				)}
				{tooltip && (
					<>
						<ToggleableElement
							toggler={toggled => (
								<CenteredSection>
									<LeftAlignedFlexBox>
										<UnderlinedSpan>{tooltip.question}</UnderlinedSpan>
										{toggled ? <CrocodileUp /> : <CrocodileDown />}
									</LeftAlignedFlexBox>
								</CenteredSection>
							)}
							skipPadding
						>
							{tooltip.answer}
						</ToggleableElement>
						<XSmallPageSection />
					</>
				)}
				<SocialMediaButtons {...socialMediaButtonProps} />
				<div>{t('copy:sessionModal.or')}</div>
			</CenteredSection>
			<Form onSubmit={handleSubmit}>
				<TextInput type="email" name="email" required value={email} />
				<PasswordInput name="password" required />
				{tier && (
					<ToggleableElement
						skipPadding
						toggler={toggled => (
							<RepulsiveRow>
								<p>{t('copy:sessionModal.referral')}</p>
								{toggled ? <CrocodileUp /> : <CrocodileDown />}
							</RepulsiveRow>
						)}
					>
						<TextInput name="referral" />
					</ToggleableElement>
				)}
				<SmallPageSection>
					<Button buttonType="RECTANGLE" wide>
						{t('copy:sessionModal.signIn')}
					</Button>
				</SmallPageSection>
			</Form>
			<SmallPageSection>
				<UnderlinedA
					onClick={() =>
						dispatch({
							type: 'SESSION_MODAL_DISPLAY_STATE',
							data: { displayState: 'FORGOT_PASSWORD' },
						})
					}
				>
					{t('copy:sessionModal.forgotPassword')}
				</UnderlinedA>
			</SmallPageSection>
			<p>{t('copy:sessionModal.notMemberYet')}</p>
			<br />
			<Button
				onClick={() =>
					dispatch({
						type: 'SESSION_MODAL_DISPLAY_STATE',
						data: pwa.openedInPwa
							? {
									displayState: 'SIGN_UP',
									tools: true,
									tier: 'no_tier',
									title: t('copy:sessionModal.signUpTitleHost'),
									message: <p>{t('copy:sessionModal.signUpSubTitleHost')}</p>,
								}
							: { displayState: isMarketplace() || getWhiteLabel().active ? 'SIGN_UP' : 'SIGN_UP_USER_OR_HOST' },
					})
				}
				buttonType="RECTANGLE"
				color="OUTLINED_BLACK"
				wide
			>
				{t('common:buttons.createAccount')}
			</Button>
		</>
	)
}

export default LoginContent
