import React, { useEffect, useRef, useState } from 'react'
import { BeaconAdjuster } from '../../../utils/helpScoutHelper'
import Button from '../../reusable_components/buttons/Button'
import FollowButton from '../../reusable_components/buttons/FollowButton'
import styled from 'styled-components'
import { PageSidePaddingWrapper } from '../../reusable_components/layout/PageComponents'
import { theme } from '../../../utils/theme'

const StyledPurchaseFooter = styled(PageSidePaddingWrapper)`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: ${({ firstAvailableDate }) => (firstAvailableDate ? 'pointer' : 'auto')};
	z-index: 2;
	bottom: ${({ showFooter }) => (showFooter ? 0 : -150)}px;
	transition: bottom 0.5s cubic-bezier(0.4, 0, 0.2, 1);
	box-shadow: ${theme.colors.lightTextColor} 0 8px 20px 0;
	left: 0;
	right: 0;
	background: ${theme.colors.lightGray};
	padding-top: ${theme.paddings.XSmall};
	padding-bottom: ${theme.paddings.XSmall};
	background: #ffffffed;
	> div:first-child > p:first-child {
		margin-bottom: ${theme.margins.XXSmall};
	}
	> div:last-child {
		min-width: fit-content;
		margin-left: ${theme.margins.XSmall};
	}
`

const PurchaseFooter = ({
	title,
	duration,
	id,
	price,
	firstAvailableDate,
	courseSideBarIsOpen,
	isOpenedInContentSideBar,
	setDisplayDates,
	numberOfConsecutiveCourseDates,
	priceText,
	t,
}) => {
	const showFooter = !isOpenedInContentSideBar || courseSideBarIsOpen
	const [ref, setRef] = useState(null)
	const footerRef = useRef(null)
	useEffect(() => setRef(footerRef), [footerRef.current])
	useEffect(() => {
		if (isOpenedInContentSideBar) return
		// Give the page footer some padding if open on CoursePage, so the PurchaseFooter doesn't cover the whole page footer
		const footer = document.getElementById('pageFooter')
		if (footer) footer.style.paddingBottom = 20 + footerRef.current.clientHeight + 'px'
	}, [])
	return (
		<>
			<StyledPurchaseFooter
				id={firstAvailableDate ? 'GTM_STICKY_PURCHASE_FOOTER' : null}
				firstAvailableDate={firstAvailableDate}
				onClick={firstAvailableDate ? () => setDisplayDates(true) : null}
				showFooter={showFooter}
				ref={footerRef}
			>
				<div>
					{/*<p>{title}</p>*/}
					<p>{priceText}</p>
				</div>
				<div>
					{firstAvailableDate ? (
						<Button color="ORANGE">{t('common:buttons.showDates')}</Button>
					) : (
						<FollowButton
							favouritableType="course"
							favouritableId={id}
							followText={t('common:buttons.notifyOfNewDates')}
							followingText={t('common:buttons.notifyingOfNewDates')}
							color="ORANGE"
							gtmId="GTM_NOTIFY_NEW_DATES_BUTTON"
						/>
					)}
				</div>
			</StyledPurchaseFooter>
			{ref && <BeaconAdjuster adjust={showFooter} position={ref.current.offsetHeight + 10} source="PURCHASE_FOOTER" />}
		</>
	)
}

export default PurchaseFooter
