import React from 'react'
import SvgShell from './SvgShell'

const Caret = props => (
	<SvgShell {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
			<rect width="256" height="256" fill="none" />
			<line
				x1="56"
				y1="232"
				x2="200"
				y2="232"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="16"
			/>
			<circle
				cx="128"
				cy="104"
				r="32"
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="16"
			/>
			<path
				d="M208,104c0,72-80,128-80,128S48,176,48,104a80,80,0,0,1,160,0Z"
				fill="none"
				stroke="#000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="16"
			/>
		</svg>
	</SvgShell>
)

export default Caret
