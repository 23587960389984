import { theme } from './theme'
import { isServerRendering } from './applicationHelper'

// Please note that these are constant, so if the screen size changes they won't update unless the component they're in re-renders
// For real time updates, use the ScreenSizeWrapper or Breakpoint components instead

export const isPhone = () =>
	theme.breakpoints.phone.minInt <= window.innerWidth && window.innerWidth <= theme.breakpoints.phone.maxInt
export const isTablet = () =>
	theme.breakpoints.tablet.minInt <= window.innerWidth && window.innerWidth <= theme.breakpoints.tablet.maxInt
export const isLaptop = () =>
	theme.breakpoints.laptop.minInt <= window.innerWidth && window.innerWidth <= theme.breakpoints.laptop.maxInt
export const isLargeLaptop = () =>
	theme.breakpoints.largeLaptop.minInt <= window.innerWidth && window.innerWidth <= theme.breakpoints.largeLaptop.maxInt
export const isMonitor = () =>
	theme.breakpoints.monitor.minInt <= window.innerWidth && window.innerWidth <= theme.breakpoints.monitor.maxInt
export const isLargeMonitor = () =>
	theme.breakpoints.largeMonitor.minInt <= window.innerWidth &&
	window.innerWidth <= theme.breakpoints.largeMonitor.maxInt
export const isTv = () => theme.breakpoints.tv.minInt <= window.innerWidth

export const getCurrentScreenTypes = railsContext => {
	const resettedScreenType = {
		isPhone: false,
		isTablet: false,
		isLaptop: false,
		isLargeLaptop: false,
		isMonitor: false,
		isLargeMonitor: false,
		isTv: false,
	}
	if (isServerRendering()) {
		if (railsContext.phone) {
			resettedScreenType.isPhone = true
		} else if (railsContext.tablet) {
			resettedScreenType.isTablet = true
		} else {
			resettedScreenType.isLaptop = true
		}
	} else {
		if (isPhone()) {
			resettedScreenType.isPhone = true
		} else if (isTablet()) {
			resettedScreenType.isTablet = true
		} else if (isLaptop()) {
			resettedScreenType.isLaptop = true
		} else if (isLargeLaptop()) {
			resettedScreenType.isLargeLaptop = true
		} else if (isMonitor()) {
			resettedScreenType.isMonitor = true
		} else if (isLargeMonitor()) {
			resettedScreenType.isLargeMonitor = true
		} else if (isTv()) {
			resettedScreenType.isTv = true
		}
	}
	return resettedScreenType
}

export const getCurrentScreenType = railsContext => {
	if (isServerRendering()) {
		if (railsContext.phone) {
			return 'phone'
		} else if (railsContext.tablet) {
			return 'tablet'
		} else {
			return 'laptop'
		}
	} else {
		if (isPhone()) {
			return 'phone'
		} else if (isTablet()) {
			return 'tablet'
		} else if (isLaptop()) {
			return 'laptop'
		} else if (isLargeLaptop()) {
			return 'largeLaptop'
		} else if (isMonitor()) {
			return 'monitor'
		} else if (isLargeMonitor()) {
			return 'largeMonitor'
		} else if (isTv()) {
			return 'tv'
		}
	}
}

export const screenSize = (width = true, height = true) => {
	const w = window
	const d = document
	const e = d.documentElement
	const g = d.getElementsByTagName('body')[0]
	const x = w.innerWidth || e.clientWidth || g.clientWidth
	const y = w.innerHeight || e.clientHeight || g.clientHeight
	// console.log('x: ' + x + 'px  |  y: ' + y + 'px')
	if (width && height) return [x, y]
	else if (width) return x
	else return y
}
