import i18n from 'i18next'
import { initReactI18next } from 'react-i18next-new'

import common_en from '../../../locales/en/common.json'
import common_sv from '../../../locales/sv/common.json'
import copy_en from '../../../locales/en/copy.json'
import copy_sv from '../../../locales/sv/copy.json'
import admin_en from '../../../locales/en/admin.json'
import admin_sv from '../../../locales/sv/admin.json'
import shared_en from '../../../locales/en/shared.json'
import shared_sv from '../../../locales/sv/shared.json'
import helmet_en from '../../../locales/en/helmet.json'
import helmet_sv from '../../../locales/sv/helmet.json'

const initializeI18n = locale => {
	i18n
		.use(initReactI18next) // passes i18n down to react-i18next
		.init({
			lng: locale,
			fallbackLng: 'sv',
			debug: false,
			interpolation: {
				escapeValue: false,
			},
			resources: {
				en: {
					common: common_en,
					copy: copy_en,
					admin: admin_en,
					shared: shared_en,
					helmet: helmet_en,
				},
				sv: {
					common: common_sv,
					copy: copy_sv,
					admin: admin_sv,
					shared: shared_sv,
					helmet: helmet_sv,
				},
			},
			react: {
				transSupportBasicHtmlNodes: true,
			},
		})
}

export default initializeI18n
