import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next-new'
import UserContext from '../../../../contexts/user/UserContext'
import DynamicModal from '../DynamicModal'
import GroupInquiryContext from '../../../../contexts/group_inquiry/GroupInquiryContext'
import GroupInquiryContent from './GroupInquiryContent'
import Button from '../../buttons/Button'
import ContactContent from './ContactContent'
import { CenteredSection, DynamicPageSectionRow } from '../../layout/PageComponents'
import { theme } from '../../../../utils/theme'

const GroupInquiryModal = () => {
	const { state, dispatch } = useContext(GroupInquiryContext)
	let { displayModal, modalContent } = state
	const { t } = useTranslation()

	const { userState } = useContext(UserContext)
	const childProps = { user: userState.user, t, groupInquiryState: state }
	return (
		<DynamicModal
			displayModal={displayModal}
			setDisplayModal={() => {
				dispatch({
					type: 'CLOSE_MODAL',
				})
			}}
		>
			{!modalContent && (
				<CenteredSection>
					<h2>{t('shared:groupInquiryModal.typeOfRequest')}</h2>
					<DynamicPageSectionRow breakpoint="phone" margins={{ inner: theme.margins.smallInt }}>
						<Button
							onClick={() =>
								dispatch({
									type: 'OPEN_MODAL',
									data: {
										modalContent: 'GROUP_INQUIRY',
									},
								})
							}
						>
							{t('shared:groupInquiryModal.groupInquiryButton')}
						</Button>
						<Button
							onClick={() =>
								dispatch({
									type: 'OPEN_MODAL',
									data: {
										modalContent: 'CONTACT',
									},
								})
							}
						>
							{t('shared:groupInquiryModal.contactButton')}
						</Button>
					</DynamicPageSectionRow>
				</CenteredSection>
			)}
			{modalContent === 'GROUP_INQUIRY' && <GroupInquiryContent {...childProps} />}
			{modalContent === 'CONTACT' && <ContactContent {...childProps} />}
		</DynamicModal>
	)
}

export default GroupInquiryModal
