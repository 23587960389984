import React from 'react'
import SvgShell from './SvgShell'

const User = props => (
	<SvgShell {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -6 28 28">
			<path
				d="M16,20.16a6.5,6.5,0,1,0-6.5-6.5A6.5,6.5,0,0,0,16,20.16Z"
				fill="none"
				stroke="currentColor"
				strokeWidth="2px"
				strokeMiterlimit="10"
				transform="translate(-6 -6.16)"
			/>
			<path
				d="M7,24.84a10.85,10.85,0,0,1,3.93-3.44A11,11,0,0,1,25,24.84"
				fill="none"
				stroke="currentColor"
				strokeWidth="2px"
				strokeLinecap="round"
				strokeLinejoin="round"
				transform="translate(-6 -6.16)"
			/>
		</svg>
	</SvgShell>
)

export default User
