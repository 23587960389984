import React from 'react'
import SvgShell from './SvgShell'

const Verified = props => (
	<SvgShell {...props}>
		<path d="M46.22,0,57.73,7.13l13.73-.08L77.09,19l11.6,7-2,12.91,5.79,11.85-9.07,9.81L81.51,73.47,68.3,77.07,59.37,87,46.22,83.24,33.06,87l-8.92-9.93-13.21-3.6L9.06,60.53,0,50.72,5.79,38.87,3.74,26l11.61-7L21,7.05l13.74.08ZM58.44,31.25,40.06,49.81,34,43.69a3.87,3.87,0,0,0-5.28-.15,3.43,3.43,0,0,0-.16,5l8.78,8.86a3.87,3.87,0,0,0,5.44,0l21.1-21.3a3.42,3.42,0,0,0-.16-5A3.87,3.87,0,0,0,58.44,31.25Z"/>
	</SvgShell>
)

export default Verified
