import React, { useContext, useEffect } from 'react'
import axios from 'axios'

import LoadingSpinner from '../../reusable_components/other/LoadingSpinner'
import { CenteredPageSection, LargePaddingWrapper } from '../../reusable_components/layout/PageComponents'
import { Text } from '../../reusable_components/Typography'
import UserContext from '../../../contexts/user/UserContext'
import { useTranslation } from 'react-i18next-new'

const LoadContainer = ({ fetching, error }) => {
	if (fetching) return <LoadingPage />
	if (error) return <ErrorPage errorMessage={error.message} messageDisplayedToUser={error.messageDisplayedToUser} />
	return null
}

// Main loading page for pages in user/host admin
const LoadingPage = () => {
	return (
		<LargePaddingWrapper>
			<CenteredPageSection>
				<LoadingSpinner />
			</CenteredPageSection>
		</LargePaddingWrapper>
	)
}

// Main error page for pages in user/host admin which is shown when the user receives an error when fetching the initial page data with graphql
const ErrorPage = ({ errorMessage, messageDisplayedToUser }) => {
	const { userState } = useContext(UserContext)
	const { t } = useTranslation()
	useEffect(() => {
		// Let us know the error to get a better understanding of what error messages the user is presented
		axios.post('/api/v1/public/slack', {
			type: 'graphql',
			data: {
				client_error_message: errorMessage,
				user_name: userState.user.fullName,
				user_id: userState.user.id,
				location: location.href,
			},
		})
	}, [])
	return (
		<LargePaddingWrapper>
			<CenteredPageSection>
				<Text textColor="errorColor">{messageDisplayedToUser || t('common:errormessages.generic')}</Text>
			</CenteredPageSection>
		</LargePaddingWrapper>
	)
}

export default LoadContainer
