import React, { useReducer } from 'react'
import UserContext from './UserContext'
import useRoutes from '../../hooks/useRoutes'

export const userProviderInitialState = {
	displayState: null,
	confirmedEmail: false,
	emailToBeConfirmed: null,
	confirmEmailAuthenticityToken: null,
	initiateOnboarding: false,
	forceOpen: false,
	callback: null,
	message: null,
	title: null,
	tooltip: null,
	showPhoneInput: false,
	tier: null,
	tools: false,
	newlyDiscoveredFeatures: {},
}

const UserProvider = ({ children, user, adminDestination, pwaSsr, rootPath }) => {
	const reducer = (state, { type, data }) => {
		if (data === undefined) return state
		switch (type) {
			case 'SESSION_MODAL_DISPLAY_STATE':
				return {
					...state,
					...data,
				}
			case 'SET_USER':
				return {
					...state,
					user: data,
					displayState: null,
				}
			case 'PWA':
				return {
					...state,
					pwa: data,
				}
			case 'NEW_FEATURE_DISCOVERED':
				const newlyDiscoveredFeatures = state.newlyDiscoveredFeatures
				newlyDiscoveredFeatures[data] = true
				return {
					...state,
					newlyDiscoveredFeatures,
				}
			case 'NEW_FAVOURITE':
				const favourites = data
				const user = Object.create(state.user)
				user.favourites = favourites
				return {
					...state,
					user,
				}
			default:
				throw new Error()
		}
	}
	let state = { ...userProviderInitialState, user, pwa: { rootPath, openedInPwa: false } }
	if (pwaSsr) {  // Any data added here also needs to be added to reportPwaLaunched in AppStructure
		state.pwa.openedInPwa = true
		state.pwa.rootPath = user.signedIn
			? useRoutes().admin.host.overview.path
			: useRoutes().admin.host.overview.url // If they are not signed in we want them to fully load that page so they get redirected
	}
	if (adminDestination) {
		// The user tried to access admin but was logged out, so force open session modal to make them log in. If they log in they are then redirect to the page they tried to access
		state.forceOpen = true
		state.displayState = 'LOG_IN'
	}
	const [userState, dispatch] = useReducer(reducer, state)
	return <UserContext.Provider value={{ userState, dispatch }}>{children}</UserContext.Provider>
}

export default UserProvider
