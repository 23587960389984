import { CenteredSection, SmallPageSection } from '../../layout/PageComponents'
import { P2, UnderlinedA } from '../../Typography'
import Button from '../../buttons/Button'
import React from 'react'

const SignUpUserOrHostContent = ({ dispatch, t }) => {
	return (
		<>
			<CenteredSection>
				<P2>{t('copy:sessionModal.signUpUserOrHost')}</P2>
				<SmallPageSection>
					<Button
						buttonType="RECTANGLE"
						wide
						onClick={() =>
							dispatch({
								type: 'SESSION_MODAL_DISPLAY_STATE',
								data: { displayState: 'SIGN_UP', tools: false, callback: null, title: null, message: null, tier: null },
							})
						}
					>
						{t('copy:sessionModal.signUpUser')}
					</Button>
				</SmallPageSection>
				<div>{t('copy:sessionModal.or')}</div>
				<SmallPageSection>
					<Button
						buttonType="RECTANGLE"
						wide
						onClick={() =>
							dispatch({
								type: 'SESSION_MODAL_DISPLAY_STATE',
								data: {
									displayState: 'SIGN_UP',
									tools: true,
									tier: 'no_tier',
									title: t('copy:sessionModal.signUpTitleHost'),
									message: <p>{t('copy:sessionModal.signUpSubTitleHost')}</p>,
								},
							})
						}
					>
						{t('copy:sessionModal.signUpHost')}
					</Button>
				</SmallPageSection>
				<p>
					{t('copy:sessionModal.alreadyHaveAccount')}&nbsp;
					<UnderlinedA
						onClick={() => dispatch({ type: 'SESSION_MODAL_DISPLAY_STATE', data: { displayState: 'LOG_IN' } })}
					>
						{t('copy:sessionModal.signIn')}
					</UnderlinedA>
				</p>
			</CenteredSection>
		</>
	)
}

export default SignUpUserOrHostContent
