import React from 'react'
import styled, { keyframes } from 'styled-components'
import { theme } from '../../../utils/theme'

const loading = keyframes`
  100% {
    transform: translateX(100%);
  }
`

const LoadingPlaceholder = styled.div`
	background: ${theme.colors.white};
	overflow: hidden;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(238, 237, 237, 0.55), rgba(0, 0, 0, 0));
		transform: translateX(-100%);
		animation: ${loading} 1s infinite;
	}
`
const ImageSectionPlaceholder = styled(LoadingPlaceholder)`
	height: 0;
	overflow: hidden;
	width: 100%;
	padding-bottom: 129%;
	box-shadow: #e7e7e7 1px 1px 4px;
`

const FirstPlaceholder = styled(LoadingPlaceholder)`
	width: 80%;
	margin-top: 20px;
	min-height: 20px;
`
const SecondPlaceholder = styled(LoadingPlaceholder)`
	width: 60%;
	margin-top: 5px;
	min-height: 20px;
`
const ThirdPlaceholder = styled(LoadingPlaceholder)`
	width: 40%;
	margin-top: 20px;
	min-height: 15px;
`
const FourthPlaceholder = styled(LoadingPlaceholder)`
	width: 90%;
	margin-top: 20px;
	min-height: 50px;
`

const Placeholder = () => (
	<div>
		<ImageSectionPlaceholder />
		<FirstPlaceholder />
		<SecondPlaceholder />
		<ThirdPlaceholder />
		<FourthPlaceholder />
	</div>
)

export default Placeholder
