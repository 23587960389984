import React from 'react'
import { useTranslation } from 'react-i18next-new'

import Envelope from '../svg_icons/Envelope'
import FollowButton from './FollowButton'

const EnvelopeFollowButton = ({ favouritableId }) => {
	const { t } = useTranslation()
	return (
		<FollowButton
			favouritableType="course"
			favouritableId={favouritableId}
			gtmId="GTM_NOTIFY_NEW_DATES_LINK"
			followText={
				<>
					<Envelope width="18px" height="18px" />
					<span>{t('common:buttons.notifyOfNewDates')}</span>
				</>
			}
			followingText={
				<>
					<Envelope width="18px" height="18px" />
					<span>{t('common:buttons.notifyingOfNewDates')}</span>
				</>
			}
			buttonType="LINK"
			color="TRANSPARENT_BLACK"
		/>
	)
}

export default EnvelopeFollowButton
