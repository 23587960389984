import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next-new'
import { theme } from '../../../utils/theme'
import { Breadcrumbs, UnderlinedSpan } from '../../reusable_components/Typography'
import { openUserMenu } from '../../layout/header/UserMenu'
import CrocodileRight from '../../reusable_components/svg_icons/CrocodileRight'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import Filter from '../../reusable_components/svg_icons/Filter'
import { LeftAlignedFlexBox } from '../../reusable_components/layout/PageComponents'

export const AdminPageWrapper = styled.div`
	width: 100%;
	padding-top: ${theme.paddings.small};
	padding-bottom: ${theme.paddings.XLarge};
	${theme.breakpoints.tablet.overAndExcluding} {
		max-width: 700px;
	}
`

export const AdminBreadcrumbs = ({ children }) => {
	const { t } = useTranslation()
	return (
		<Breadcrumbs style={{ marginBottom: theme.margins.small }}>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
				<UnderlinedSpan onClick={openUserMenu}>{t('shared:userMenu.myAccount')}</UnderlinedSpan>
			</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
				<span>{t('shared:userMenu.myAccount')}</span>
			</Breakpoint>
			<CrocodileRight height="16px" width="16px" />
			<span>{children}</span>
		</Breadcrumbs>
	)
}

export const AdminFilterTitle = props => {
	const { t } = useTranslation()
	return (
		<LeftAlignedFlexBox innerMargin={theme.margins.XXSmall}>
			<p>{t('shared:filterContainer.filter')}</p>
			<Filter />
		</LeftAlignedFlexBox>
	)
}
