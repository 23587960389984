import React, { useContext, useEffect, useState } from 'react'
import {
	CenteredSection,
	Divider,
	DynamicPageSection,
	PageSidePaddingWrapper,
	SmallPaddingWrapper,
} from '../../reusable_components/layout/PageComponents'
import { theme } from '../../../utils/theme'
import Button from '../../reusable_components/buttons/Button'
import styled, { css } from 'styled-components'
import RadioButtonChecked from '../../reusable_components/svg_icons/RadioButtonChecked'
import RadioButton from '../../reusable_components/svg_icons/RadioButton'
import DynamicModal from '../../reusable_components/modals/DynamicModal'
import FollowButton from '../../reusable_components/buttons/FollowButton'
import LinkButton from '../../reusable_components/buttons/LinkButton'
import CheckoutFooter from './CheckoutFooter'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import GroupInquiryContext from '../../../contexts/group_inquiry/GroupInquiryContext'
import { hideBeacon, showBeacon } from '../../../utils/helpScoutHelper'
import { P4 } from '../../reusable_components/Typography'
import EnvelopeFollowButton from '../../reusable_components/buttons/EnvelopeFollowButton'
import { DateAndTimeInfo } from '../../reusable_components/other/CourseComponents'

const ModalWrapper = styled(PageSidePaddingWrapper)`
	padding-top: ${theme.paddings.large};
	padding-bottom: ${theme.paddings.small};
	${theme.breakpoints.tablet.overAndExcluding} {
		padding-left: ${theme.paddings.large};
		padding-right: ${theme.paddings.large};
	}
`

const NoDatesAvailableBox = styled(SmallPaddingWrapper)`
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
	background: ${({ background }) => background || theme.colors.gray};
	color: ${({ color }) => color || theme.colors.textColor};
	border-radius: 2px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	> *:last-child {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: ${theme.margins.XSmall};
		> button {
			margin-left: ${theme.margins.XXSmall};
		}
	}
`

const StyledBookableDate = styled(SmallPaddingWrapper)`
	display: flex;
	flex-direction: column;
	background: ${theme.colors.gray};
	border-radius: 2px;
	height: 100%;
	font-size: ${theme.fontSizes.p3};
	line-height: ${theme.lineHeights.p3};
	> p {
		font-size: ${theme.fontSizes.p4};
		line-height: ${theme.lineHeights.p4};
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		min-height: 120px;
	}
	color: ${({ full }) => (full ? theme.colors.lightTextColor : theme.colors.textColor)};
	> div:first-child {
		flex: 1;
	}
	> p:last-of-type {
		margin-bottom: ${theme.margins.XSmall};
	}
	> div:last-child {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`
const DateBox = styled.div`
	padding: ${theme.paddings.small};
	cursor: pointer;
	background: ${theme.colors.gray};
	margin-top: ${theme.margins.XSmall};
	font-size: ${theme.fontSizes.p3};
	line-height: ${theme.lineHeights.p3};
	> p {
		font-size: ${theme.fontSizes.p4};
		line-height: ${theme.lineHeights.p4};
	}
	${({ full }) =>
		full &&
		css`
			color: ${theme.colors.lightTextColor};
			cursor: default;
			${DateCheckbox} {
				cursor: default;
				opacity: 0.3;
			}
		`}
`
const DateBoxMainRow = styled.div`
	display: flex;
	justify-content: space-between;
	> div:first-child {
		> p:last-child {
			margin-top: ${theme.margins.XSmall};
		}
	}
`
const DateCheckbox = styled.div`
	width: 34px;
	height: 34px;
	cursor: pointer;
	color: ${theme.colors.orange};
	> svg {
		width: 34px;
		height: 34px;
	}
`
const StyledDivider = styled(Divider)`
	margin-bottom: ${theme.margins.XSmall};
	margin-top: ${theme.margins.XSmall};
`

const BookableDates = props => {
	const {
		price,
		bookableDates = [],
		id,
		minParticipants,
		isTeambuilding,
		displayDates,
		setDisplayDates,
		priceText,
		host,
		t,
	} = props
	const { dispatch } = useContext(GroupInquiryContext)
	const [selectedDate, setSelectedDate] = useState(null)
	const checkoutFooterProps = { price, priceText, isTeambuilding, displayDates, selectedDate, t }
	const datesToShow = bookableDates.slice(0, 4)
	let desktopExtraDate = null
	if (datesToShow.length >= 4) {
		desktopExtraDate = datesToShow[3]
	}
	const bookableDateProps = { t, courseId: id, priceText }
	let title
	let subTitle
	if (bookableDates.length === 1) {
		// Only one date is available to book
		title = t('copy:coursePage.chooseFromOneDate')
	} else {
		// Multiple dates are available to book
		title = t('copy:coursePage.chooseDate')
		subTitle = t('copy:coursePage.allAvailableDates')
	}
	return bookableDates.length > 0 ? (
		<>
			{datesToShow.slice(0, 3).map((date, key) => (
				<BookableDate key={key} {...date} {...bookableDateProps} />
			))}
			{desktopExtraDate && (
				<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
					<BookableDate {...desktopExtraDate} {...bookableDateProps} />
				</Breakpoint>
			)}
			{bookableDates.length >= 4 && (
				<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
					<div>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<EnvelopeFollowButton favouritableId={id} />
						</div>
						<SmallPaddingWrapper style={{ paddingTop: theme.paddings.XSmall }}>
							<CenteredSection>
								<SeeAllDatesButton t={t} setDisplayDates={setDisplayDates} />
							</CenteredSection>
						</SmallPaddingWrapper>
					</div>
				</Breakpoint>
			)}
			<DynamicModal
				displayModal={!!displayDates}
				setDisplayModal={setDisplayDates}
				bottomOffset="80px"
				useDefaultWrapper={false}
			>
				<ModalWrapper>
					<h2>{title}</h2>
					{subTitle && <p>{subTitle}</p>}
					<div>
						{bookableDates.map((bookableDate, key) => {
							const { id, maxParticipants, showMaxParticipants, seatsLeft, full } = bookableDate
							return (
								<DateBox key={key} onClick={full ? null : () => setSelectedDate(bookableDate)} full={full}>
									<DateBoxMainRow>
										<div>
											<DateAndTimeInfo courseDate={bookableDate} />
											<P4>
												{full ? (
													t('common:workshop.full')
												) : (
													<>
														{showMaxParticipants
															? t('copy:coursePage.seatsLeftOutOf', { seatsLeft, maxParticipants })
															: t('copy:coursePage.seatsLeft', { seatsLeft })}
													</>
												)}
											</P4>
										</div>
										{full ? (
											<div>
												<FollowButton
													favouritableType="course"
													favouritableId={props.id}
													followText={t('common:buttons.waitlist')}
													followingText={t('common:buttons.onWaitlist')}
													color="OUTLINED_BLACK"
													skipButtonWhenFavourited
													gtmId="GTM_NOTIFY_NEW_DATES_WAITLIST_BUTTON"
												/>
											</div>
										) : (
											<DateCheckbox>{selectedDate?.id === id ? <RadioButtonChecked /> : <RadioButton />}</DateCheckbox>
										)}
									</DateBoxMainRow>
								</DateBox>
							)
						})}
					</div>
					<DynamicPageSection margins={{ all: theme.margins.smallInt }}>
						<LinkButton
							onClick={() => {
								setDisplayDates(false)
								dispatch({
									type: 'OPEN_MODAL',
									data: { minParticipants, courseId: id, hostName: host.fullName },
								})
							}}
						>
							{t('copy:coursePage.bookPrivateDateGroup')}
						</LinkButton>
					</DynamicPageSection>
					<EnvelopeFollowButton favouritableId={id} />
				</ModalWrapper>
				<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
					<CheckoutFooter {...checkoutFooterProps} />
				</Breakpoint>
			</DynamicModal>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
				<MobileFooter {...checkoutFooterProps} />
			</Breakpoint>
		</>
	) : (
		<NoDatesAvailableBox>
			<p>{t('copy:coursePage.noNewDates')}</p>
			<div>
				<div>
					<p>{priceText}</p>
				</div>
				<FollowButton
					favouritableType="course"
					favouritableId={id}
					followText={t('common:buttons.notifyOfNewDates')}
					followingText={t('common:buttons.notifyingOfNewDates')}
					color="ORANGE"
					gtmId="GTM_NOTIFY_NEW_DATES_BUTTON"
				/>
			</div>
		</NoDatesAvailableBox>
	)
}

export default BookableDates

const BookableDate = props => {
	const { url, full, seatsLeft, maxParticipants, showMaxParticipants, consecutiveCourseDates, courseId, priceText, t } =
		props
	const numberOfDates = consecutiveCourseDates.length + 1
	return (
		<a href={full ? null : url}>
			<StyledBookableDate full={full}>
				<DateAndTimeInfo courseDate={props} />
				{numberOfDates > 1 ? <StyledDivider /> : <br />}
				<p>
					{full ? (
						t('common:workshop.full')
					) : (
						<>
							{showMaxParticipants
								? t('copy:coursePage.seatsLeftOutOf', { seatsLeft, maxParticipants })
								: t('copy:coursePage.seatsLeft', { seatsLeft })}
						</>
					)}
				</p>
				<div>
					<p>{priceText}</p>
					{full ? (
						<FollowButton
							favouritableType="course"
							favouritableId={courseId}
							followText={t('common:buttons.waitlist')}
							followingText={t('common:buttons.onWaitlist')}
							color="OUTLINED_BLACK"
							skipButtonWhenFavourited
							gtmId="GTM_NOTIFY_NEW_DATES_WAITLIST_BUTTON"
						/>
					) : (
						<Button color="ORANGE">{t('common:buttons.choose')}</Button>
					)}
				</div>
			</StyledBookableDate>
		</a>
	)
}

export const SeeAllDatesButton = ({ t, setDisplayDates }) => (
	<Button onClick={() => setDisplayDates(true)} color="OUTLINED_BLACK">
		{t('common:buttons.showAllDates')}
	</Button>
)

const MobileFooter = props => {
	// The Help Scout chat button is in the way when choosing a date to book on mobile, so hide it when choosing dates
	useEffect(() => {
		if (props.displayDates) {
			hideBeacon()
		} else {
			showBeacon()
		}
		return () => showBeacon()
	})
	return <CheckoutFooter {...props} />
}
