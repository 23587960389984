import React from 'react'
import SvgShell from './SvgShell'

const cameraStyle = {
	fill: 'none',
	stroke: '#000',
	strokeLinecap: 'round',
	strokeLineJoin: 'round',
	strokeWidth: '2px',
}
const Camera = props => (
	<SvgShell {...props}>
		<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 19">
			<path
				style={cameraStyle}
				d="M3,7.5H19a4,4,0,0,1,4,4v12a1,1,0,0,1-1,1H6a4,4,0,0,1-4-4V8.5a1,1,0,0,1,1-1Z"
				transform="translate(-1 -6.5)"
			/>
			<path style={cameraStyle} d="M23,14l7-4V22l-7-4" transform="translate(-1 -6.5)" />
		</svg>
	</SvgShell>
)

export default Camera
