import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next-new'
import { theme } from '../../../utils/theme'
import { limitRows } from '../PageMixins'

import { P2, P4, P5 } from '../Typography'
import ResponsiveImage from '../responsive/ResponsiveImage'
import ContentSideBarContext from '../../../contexts/content_side_bar/ContentSideBarContext'
import { ScarcityBadge, StyledScarcityBadge } from '../other/CourseComponents'
import Clock from '../../reusable_components/svg_icons/Clock'

const ContentContainer = styled.div`
	margin-top: ${theme.margins.XSmall};
	${({ featured }) =>
		featured &&
		css`
			border-bottom-right-radius: 2px;
			border-bottom-left-radius: 2px;
			background: white;
			padding: ${theme.paddings.XSmall};
			margin-top: 0;
		`}
`
const CityAndRating = styled.div`
	display: flex;
	justify-content: space-between;
	> span:first-child {
		${limitRows(1)};
	}
	> span:last-child {
		display: flex;
	}
`
const Title = styled(P2)`
	margin-top: ${theme.margins.XXSmall};
	margin-bottom: ${theme.margins.XXSmall};
	min-height: 48px;
	width: 90%;
	${limitRows(2)}
`
const HostName = styled(P4)`
	color: ${theme.colors.lightTextColor};
	${limitRows(1)};
`
const DurationAndPrice = styled.div`
	margin-top: ${theme.margins.XSmall};
	display: flex;
	align-items: center;
	svg {
		margin-right: ${theme.margins.XXSmall};
	}
`
export const CourseCardWrapper = styled.div`
	overflow: hidden;
	transition: transform 0.1s;
	@media (min-width: 769px) {
		&:hover {
			transform: scale(1.02);
		}
	}
	> a:first-child {
		display: block;
	}
	${StyledScarcityBadge} {
		position: absolute;
		bottom: ${theme.margins.XSmall};
		left: ${theme.margins.XSmall};
	}
`
const DateBadge = styled(P5)`
	position: absolute;
	top: ${theme.margins.XSmall};
	left: ${theme.margins.XSmall};
	background: white;
	border-radius: 100px;
	padding: ${theme.paddings.XSmall} ${theme.paddings.small};
	min-width: max-content;
	white-space: nowrap; /* Force the text on one row on Safari mobile for longer dates */
`
/*const TagsWrapper = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: ${theme.margins.small};
	margin-top: ${theme.margins.small};
	> a {
		background: ${theme.colors.gray};
		border-radius: 100px;
		padding: ${theme.paddings.XSmall};
	}
`*/

const CourseCard = ({ course, featured, skipUrlManipulation }) => {
	const {
		title,
		province,
		physical,
		image,
		url,
		host,
		tags,
		scarcity,
		reviewData,
		price,
		duration,
		firstAvailableDate,
		numberOfConsecutiveCourseDates,
		courseCardFullName,
	} = course
	const { rating, count } = reviewData
	const { t } = useTranslation()
	const priceText = price ? `${price}kr` : t('common:payments.free')
	return (
		<ContentSideBarContext.Consumer>
			{({ state, dispatch }) => {
				const openCoursePage = event => {
					// The card is an a tag link so it can be indexed by Google, but we want the overlay to open instead of
					// the user being rerouted to the course page, so prevent the default of the a tag.
					event.preventDefault()
					const data = { content: course, contentType: 'COURSE' }
					if (skipUrlManipulation) data.skipUrlManipulation = true
					dispatch({ type: 'NEW_CONTENT', data })
				}
				return (
					<CourseCardWrapper>
						<a href={url} onClick={openCoursePage} draggable={false}>
							<ResponsiveImage
								imagePath={image}
								alt={title}
								type={featured ? 'BIG_RECTANGLE' : 'BIG_VERTICAL_RECTANGLE'}
								sourceWidth={theme.breakpoints.phone.maxInt}
							>
								{!featured && firstAvailableDate && (
									<DateBadge>
										{t('common:workshop.next')}&nbsp;
										{firstAvailableDate.abbreviatedDate}
									</DateBadge>
								)}
								{!featured && scarcity && <ScarcityBadge scarcity={scarcity} />}
							</ResponsiveImage>
							<ContentContainer featured={featured}>
								<CityAndRating>
									<span>{physical ? province : 'Online'}</span>
									<span>
										{count ? (
											<>
												<P4 textColor="orange">★</P4>
												&nbsp;
												{rating}
												&nbsp;
												<P4 textColor="lightTextColor">({count})</P4>
											</>
										) : (
											<>{t('common:workshop.new')}</>
										)}
									</span>
								</CityAndRating>
								<Title>{title}</Title>
								<HostName>{courseCardFullName}</HostName>
								<DurationAndPrice>
									<Clock width={16} height={16} />
									{(numberOfConsecutiveCourseDates > 1 && (
										<>
											{numberOfConsecutiveCourseDates}{' '}
											{t('common:workshop.session', { count: numberOfConsecutiveCourseDates })} · {priceText}
										</>
									)) || (
										<>
											{duration} · {priceText}
										</>
									)}
								</DurationAndPrice>
							</ContentContainer>
						</a>
						{/*<TagsWrapper>
							{tags.map((tag, key) => (
								<Link to={tag.path} onClick={() => dispatch({ type: 'HIDE_CONTENT', data: {} })} key={key}>
									<P4>#{tag.slug}</P4>
								</Link>
							))}
						</TagsWrapper>*/}
					</CourseCardWrapper>
				)
			}}
		</ContentSideBarContext.Consumer>
	)
}

CourseCard.propTypes = {
	course: PropTypes.object,
}

export default CourseCard
