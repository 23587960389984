import React from 'react'
import SvgShell from './SvgShell'

const Filter = props => (
	<SvgShell {...props}>
		<svg style={{ stroke: 'currentcolor' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
			<line
				style={{ strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '16px' }}
				x1="136"
				y1="171.99"
				x2="40"
				y2="171.99"
			/>
			<line
				style={{ strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '16px' }}
				x1="216"
				y1="171.99"
				x2="168"
				y2="171.99"
			/>
			<line
				style={{ strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '16px' }}
				x1="72"
				y1="83.99"
				x2="40"
				y2="83.99"
			/>
			<line
				style={{ strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '16px' }}
				x1="216"
				y1="83.99"
				x2="104"
				y2="83.99"
			/>
			<line
				style={{ strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '16px' }}
				x1="104"
				y1="59.99"
				x2="104"
				y2="107.99"
			/>
			<line
				style={{ strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '16px' }}
				x1="168"
				y1="195.99"
				x2="168"
				y2="147.99"
			/>
		</svg>
	</SvgShell>
)

export default Filter
