import React from 'react'
import ScreenSizeContext from '../../../contexts/screen_size/ScreenSizeContext'

/*  Wrapp a component with this to give it access to updated screen type
 *   Good to use for images so they can have a more dynamic size and quality
 * */
export default ({ children }) => (
	<ScreenSizeContext.Consumer>
		{({ screenType, screenTypes }) =>
			React.Children.map(children, (child, key) => child && React.cloneElement(child, { screenType, screenTypes }))
		}
	</ScreenSizeContext.Consumer>
)
