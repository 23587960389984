import React, { useState } from 'react'
import Form from '../../forms/Form'
import axios from 'axios'
import TextInput from '../../forms/TextInput'
import TextAreaInput from '../../forms/TextAreaInput'
import Button from '../../buttons/Button'
import { Markdown } from '../../Typography'

const ContactContent = ({ user, groupInquiryState, t }) => {
	let { hostName, courseId, hostId } = groupInquiryState

	const [inquirySent, setInquirySent] = useState(false)
	const { fullName, email } = user
	const onSubmit = (inputValues, onSubmitFinished) => {
		const url = '/api/v1/public/team_building/new_contact_request'
		axios({
			method: 'post',
			url: url,
			data: {
				group_inquiry: {
					courseId: courseId,
					hostId: hostId,
					...inputValues,
				},
			},
		})
			.then(response => {
				setInquirySent(true)
			})
			.catch(error => {
				onSubmitFinished(t('common:errormessages.generic'))
			})
	}
	return inquirySent ? (
		<>
			<h2>{t('shared:groupInquiryModal.thankYouHeadline')}</h2>
			<p>{t('shared:groupInquiryModal.thankYouBody')}</p>
		</>
	) : (
		<>
			<h2>
				{t('shared:groupInquiryModal.requestContactHeadline')} {hostName}
			</h2>
			<Markdown linkTarget="_blank">{t('shared:groupInquiryModal.contactText')}</Markdown>
			<br />
			<Form onSubmit={onSubmit}>
				<TextInput name="name" value={fullName} required />
				<TextInput name="email" value={email} type="email" required />
				<TextAreaInput name="groupInquiryMessage" desiredName="message" required />
				<Button buttonType="RECTANGLE" wide>
					{t('common:buttons.send')}
				</Button>
			</Form>
		</>
	)
}

export default ContactContent
