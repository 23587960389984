import React, { useContext } from 'react'
import GroupInquiryContext from '../../../../contexts/group_inquiry/GroupInquiryContext'
import PropTypes from 'prop-types'

// Wrap around one or more elements to make them open the GroupInquiryModal on click
const GroupInquiryModalTrigger = ({ children, minParticipants = 4, courseId, hostId, hostName, modalContent }) => {
	const { dispatch } = useContext(GroupInquiryContext)
	return (
		<>
			{React.Children.map(children, child => {
				const { onClick, style } = child
				return React.cloneElement(
					child,
					{
						onClick: () => {
							onClick && onClick()
							dispatch({
								type: 'OPEN_MODAL',
								data: {
									minParticipants,
									courseId,
									hostId,
									hostName,
									modalContent,
								},
							})
						},
						style: { ...style, cursor: 'pointer' },
					},
					child.props.children,
				)
			})}
		</>
	)
}

GroupInquiryModalTrigger.propTypes = {
	minParticipants: PropTypes.number, // Required for GroupInquiryContent if made from a course page, but optional otherwise
	courseId: PropTypes.number, // Needed for ContactContent to let us know the request was made from the course page. Required for GroupInquiryContent if made from a course page, but optional otherwise
	hostId: PropTypes.number, // Needed for ContactContent to let us know the request was made from the host page
	hostName: PropTypes.string, // Needed for ContactContent to display the host's name in the form
	// modalContent: PropTypes.oneOf(['GROUP_INQUIRY', 'CONTACT']), // Force the GroupInquiryModal to show either GroupInquiryContent or ContactContent
}

export default GroupInquiryModalTrigger
