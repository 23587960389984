import React from 'react'
import axios from 'axios'
import { Trans } from 'react-i18next-new'
import useRoutes from '../../../../hooks/useRoutes'

import { CenteredSection, SmallPageSection } from '../../layout/PageComponents'
import Button from '../../buttons/Button'
import Form from '../../forms/Form'
import TextInput from '../../forms/TextInput'
import { P2, UnderlinedA } from '../../Typography'
import CheckboxInput from '../../forms/CheckboxInput'
import getWhiteLabel from '../../../../hooks/getWhiteLabel'

const FavouriteContent = ({ dispatch, t, onLogin, ...contextData }) => {
	const routes = useRoutes()
	const { message, title } = contextData
	const handleSubmit = (inputValues, onSubmitFinished) => {
		const { email, newsletter } = inputValues
		axios({
			method: 'post',
			url: routes.signup.create.url,
			data: {
				user: {
					email,
					newsletter,
				},
				initiate_onboarding: false,
			},
		})
			.then(response => {
				if (response.data.success === true) {
					onLogin(response.data.user)
				} else {
					onSubmitFinished(t('common:errormessages.generic'))
				}
			})
			.catch(error => {
				if (error.response) {
					onSubmitFinished(error.response.data.errorMessage || t('common:errormessages.generic'))
				}
			})
	}
	return (
		<>
			<Form onSubmit={handleSubmit}>
				<CenteredSection>
					{title && <P2>{title}</P2>}
					{message && <SmallPageSection>{message}</SmallPageSection>}
				</CenteredSection>
				<TextInput type="email" name="email" required />
				<CheckboxInput
					name="acceptPolicies"
					required
					label={
						<Trans i18nKey={'common:forms:createAccount'} values={{ val: 'useragreements' }}>
							I accept the
							<a target="_blank" rel="noopener noreferrer" href={routes.custom.termsOfUse.url}>
								terms of use
							</a>
							and that an account is created for me
						</Trans>
					}
				/>
				{!getWhiteLabel().active && <CheckboxInput name="newsletter" />}
				<SmallPageSection>
					<Button buttonType="RECTANGLE" wide>
						{t('common:buttons.notifyMe')}
					</Button>
				</SmallPageSection>
				<CenteredSection>
					<p>
						{t('copy:sessionModal.alreadyHaveAccount')}&nbsp;
						<UnderlinedA
							onClick={() => dispatch({ type: 'SESSION_MODAL_DISPLAY_STATE', data: { displayState: 'LOG_IN' } })}
						>
							{t('copy:sessionModal.signIn')}
						</UnderlinedA>
					</p>
				</CenteredSection>
			</Form>
		</>
	)
}

export default FavouriteContent
