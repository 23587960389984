import React from 'react'
import { theme } from '../../../utils/theme'
import styled from 'styled-components'
import CrocodileRight from '../../reusable_components/svg_icons/CrocodileRight'
import { PageSidePaddingWrapper } from '../../reusable_components/layout/PageComponents'

const Footer = styled(PageSidePaddingWrapper)`
	${theme.breakpoints.tablet.upToAndIncluding} {
		position: fixed;
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		padding-left: ${theme.paddings.large};
		padding-right: ${theme.paddings.large};
	}
	display: flex;
	background: ${theme.colors.lightGray};
	bottom: 0;
	left: 0;
	z-index: 8999;
	min-height: 80px;
	align-items: center;
	justify-content: space-between;
	> div:first-child > p:first-child {
		margin-bottom: ${theme.margins.XXSmall};
	}
`
const CheckoutBox = styled.div`
	margin-left: ${theme.margins.XSmall};
	display: flex;
	align-items: center;
	cursor: ${({ selectedDate }) => (selectedDate ? 'pointer' : 'auto')};
`
const CheckoutButton = styled.div`
	margin-left: ${theme.margins.XSmall};
	min-width: 44px;
	min-height: 44px;
	background: ${theme.colors.orange};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	& > svg {
		color: white;
		width: 34px;
		height: 34px;
		transform: ${props => (props.selectedDate ? 'rotateZ(0)' : 'rotateZ(-90deg)')};
		transition: transform 0.5s;
	}
`
const CheckoutFooter = props => {
	const { price, isTeambuilding, displayDates, selectedDate, t } = props
	return (
		displayDates && (
			<Footer>
				<div>
					{price ? (
						<>
							<p>
								{t('copy:coursePage.price')}
								{isTeambuilding && ` ${t('copy:coursePage.from').toLowerCase()}`}
							</p>
							<p>{price} kr</p>
						</>
					) : (
						t('common:payments.free')
					)}
				</div>
				<CheckoutBox
					selectedDate={selectedDate}
					onClick={() => {
						if (selectedDate) location = selectedDate.url
					}}
				>
					<p>
						{selectedDate
							? t(price === 0 ? 'common:buttons.next' : 'copy:coursePage.checkout')
							: t('copy:coursePage.chooseDateFooter')}
					</p>
					<CheckoutButton selectedDate={selectedDate}>
						<CrocodileRight />
					</CheckoutButton>
				</CheckoutBox>
			</Footer>
		)
	)
}

export default CheckoutFooter
