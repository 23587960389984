import React from 'react'
import SvgShell from './SvgShell'

const HeartFilled = props => (
	<SvgShell {...props}>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.92 22.78">
			<path
				style={{
					fill: 'currentColor',
					stroke: 'currentColor',
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
				}}
				d="M16.71,26.49,26.84,16.36a6.76,6.76,0,0,0,.5-9.2A6.48,6.48,0,0,0,25.23,5.6,6.62,6.62,0,0,0,22.67,5a6.51,6.51,0,0,0-2.58.46A6.67,6.67,0,0,0,17.9,6.9L16,8.81,14.36,7.16a6.76,6.76,0,0,0-9.2-.5A6.48,6.48,0,0,0,3.6,8.77,6.62,6.62,0,0,0,3,11.33a6.51,6.51,0,0,0,.46,2.58A6.67,6.67,0,0,0,4.9,16.1L15.29,26.49a1,1,0,0,0,1.42,0Z"
				transform="translate(-2.5 -4.5)"
			/>
		</svg>
	</SvgShell>
)

export default HeartFilled
