import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'
import pageScroller from '../../../utils/pageScroller'
import { useTranslation } from 'react-i18next-new'

import TextInput from './TextInput'
import PasswordInput from './PasswordInput'
import DateInput from './DateInput'
import TimeInput from './TimeInput'
import MapInput from './MapInput'
import TextAreaInput from './TextAreaInput'
import ImageInput from './ImageInput'
import SelectInput from './SelectInput'
import CheckboxInput from './CheckboxInput'
import Button from '../buttons/Button'
import CreditCardSection from '../../purchases/new/CreditCardSection'
import { isLocalImage } from '../../../utils/imageHelper'
import { theme } from '../../../utils/theme'
import DynamicModal from '../modals/DynamicModal'
import { CenteredSection } from '../layout/PageComponents'
import { Markdown } from '../Typography'
import UserContext from '../../../contexts/user/UserContext'

// This form component detects inputs and submit buttons amongst its children and adds default props to them,
// e.g. onChange, label, ref and placeholder. Input value is kept it state. The form also keeps track if the format
// of the input is valid or not with the inputInvalidFormats state. Label and placeholder is fetched from copy automatically,
// so input name needs to have a corresponding value in the common translation file under "forms". If multiple inputs of the same name
// exists, then the "desiredName" prop can be used to get the correct placeholder/label. E.g. a form for two people will have two first name fields
// with different name props to distinguish them, and can therefore use desiredName="firstName" to get the correct placeholder/label.

// The form also handles submissions and error handling

// Only the components CheckboxInput, TextInput, CardSection and Button are currently supported.

// Remember to call onSubmitFinished after form is submitted, if the form should be able to submit again e.g. if the form submission fails.

// If none submit buttons needs to be added to the form, set their type to "button"

class FormEngine extends React.Component {
	constructor(props) {
		super(props)
		this.handleUserInput = this.handleUserInput.bind(this)
		this.onSubmitFinished = this.onSubmitFinished.bind(this)
		this.onErrorModalClose = this.onErrorModalClose.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.onCreditCardReady = this.onCreditCardReady.bind(this)
		this.returnValuesAndRefsToParent = this.returnValuesAndRefsToParent.bind(this)
		this.setInputParentTogglers = this.setInputParentTogglers.bind(this)
		// A flag which gets set to true if the form finds new inputs during a render.
		// Those new inputs gets temporarily added to newInputValues and newInputInvalidFormats before added to state
		this.newInputsInitialized = false
		this.newInputValues = {}
		this.newInputInvalidFormats = {}
		// miscData is extra data which can vary depending on the input type. Map input uses it for also submitting latitude/longitude for example
		this.newMiscData = {}
		// This keeps track of which inputs are currently present in the form and is used to clear old input values from state on submit
		this.currentInputNames = []
		// The form updates inputs' values internally on user input. If those values are updated from the parent however,
		// then this object helps notice those changes.
		this.prevInputValuesFromParent = {}
		this.inputParentTogglers = {} // Keeps track of ToggleableElement parents to inputs. These will need to be toggled (opened) and scrolled to if an input hidden in one is invalid
		this.state = {
			inputInvalidFormats: this.newInputInvalidFormats,
			inputValues: this.newInputValues,
			miscData: {},
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.setNewInputData()
	}
	componentDidMount() {
		if (this.props.valuesAndRefsCallback) {
			this.props.valuesAndRefsCallback(this.returnValuesAndRefsToParent)
		}
		if (this.props.manualSubmitTrigger) {
			this.props.manualSubmitTrigger(this.handleSubmit)
		}
		this.setNewInputData()
		if (this.props.onLoad) {
			this.props.onLoad(this.state.inputValues)
		}
	}
	componentWillUnmount() {
		if (this.state.showSavedTextTimeout) {
			clearTimeout(this.state.showSavedTextTimeout)
		}
	}
	setNewInputData() {
		// Clear old values if their respective input elements have been removed
		const { inputValues, inputInvalidFormats, miscData } = this.state
		let inputDeleted = false
		for (const inputName of Object.keys(inputValues)) {
			if (!this.currentInputNames.includes(inputName)) {
				inputDeleted = true
				delete inputValues[inputName]
				delete inputInvalidFormats[inputName]
				delete miscData[inputName]
				delete this.prevInputValuesFromParent[inputName]
			}
		}
		if (this.newInputsInitialized || inputDeleted) {
			// New input fields have been detected or old ones deleted, so add their data to state
			this.newInputsInitialized = false
			this.setState({
				inputInvalidFormats: { ...inputInvalidFormats, ...this.newInputInvalidFormats },
				inputValues: { ...inputValues, ...this.newInputValues },
				miscData: { ...miscData, ...this.newMiscData },
			})
			this.newInputValues = {}
			this.newInputInvalidFormats = {}
		}
	}

	// Traverses all children and adds props to them if they are inputs or a button
	recursiveWrapper = children =>
		React.Children.map(children, child => {
			if (!child) return
			if (this.isInputElement(child)) {
				return this.cloneInputElement(child)
			} else if (this.isButtonElement(child)) {
				return this.cloneButtonElement(child)
			} else if (this.isCreditCardElement(child)) {
				return this.cloneCreditCardElement(child)
			} else if (child.props && child.props.children && child.type !== Markdown) {
				// Markdown doesn't work if you clone it for some reason
				return React.cloneElement(child, {}, this.recursiveWrapper(child.props.children))
			} else {
				return child
			}
		})

	// Add props to input children
	cloneInputElement(oldInput) {
		let { label, labelElement, name, desiredName, placeholder, value, checked, onChange, premium } = oldInput.props
		let { inputValues, inputInvalidFormats, miscData } = this.state
		const { t, user, nonWhiteBackground } = this.props

		// New input so initialize it
		if (!this[name + 'Ref']) {
			this[name + 'Ref'] = React.createRef()
		}

		// Initialize the values if the parent doesn't send in any.
		value = value ?? ''
		checked = checked ?? false

		// Input value was changed from parent, so update it to that value.
		// This can happen either when the input first appears or when it's value is updated from the server
		if (
			(this.isTextInput(oldInput) ||
				this.isFileInput(oldInput) ||
				this.isSelectInput(oldInput) ||
				this.isMapInput(oldInput)) &&
			this.prevInputValuesFromParent[name] !== value
		) {
			this.newInputValues[name] = value
			this.prevInputValuesFromParent[name] = value
			this.newInputInvalidFormats[name] = false
			this.newInputsInitialized = true
			this.newMiscData[name] = oldInput.props.miscData
			if (this.isMapInput(oldInput)) this.newMiscData[name].googleAddress = value // Initialise the googleAddress with the current address
		} else if (this.isCheckbox(oldInput) && this.prevInputValuesFromParent[name] !== checked) {
			this.newInputValues[name] = checked
			this.prevInputValuesFromParent[name] = checked
			this.newInputInvalidFormats[name] = false
			this.newInputsInitialized = true
			this.newMiscData[name] = oldInput.props.miscData
		}
		let newProps = {
			label: label || (labelElement ? '' : t(`common:forms.${desiredName || name}`)), // Don't add a label if a label element has been chosen instead
			miscData: miscData[name] || oldInput.props.miscData, // miscData[name] will be undefined on the very first render, so initialise with miscData from props
			invalidFormat: inputInvalidFormats[name],
			disabled: premium && user.needsStandard,
			premium: premium,
			t: t,
			nonWhiteBackground: nonWhiteBackground,
			setParentTogglers: (name, parentTogglers) => {
				this.setInputParentTogglers(name, parentTogglers)
			},
			onChange: (event, newMiscData) => {
				this.handleUserInput(event, newMiscData, onChange)
			},
			ref: this[name + 'Ref'],
		}
		if (this.isTextInput(oldInput) || this.isMapInput(oldInput) || this.isSelectInput(oldInput)) {
			newProps = {
				...newProps,
				placeholder: placeholder || t(`common:forms.placeholders.${desiredName || name}`),
				value: inputValues[name],
			}
		} else if (this.isCheckbox(oldInput)) {
			newProps = {
				...newProps,
				checked: inputValues[name],
			}
		} else if (this.isFileInput(oldInput)) {
			newProps = {
				...newProps,
				value: inputValues[name] || value, // Update displayed image with newly selected image
			}
		}
		this.currentInputNames.push(name)
		return React.cloneElement(oldInput, newProps)
	}
	// Add props to submit button children
	cloneButtonElement(oldButton) {
		let { submitted, showSavedText } = this.state
		if (!this.submitButtonRef) {
			this.submitButtonRef = React.createRef()
		}
		const disableButton = this.props.forceDisable || this.getInvalidInputForSubmit()
		let newProps = {
			submitted: submitted,
			children: showSavedText && oldButton.props.savedText ? oldButton.props.savedText : oldButton.props.children,
			text: showSavedText && oldButton.props.savedText ? oldButton.props.savedText : oldButton.props.text,
			disable: !!disableButton,
			ref: this.submitButtonRef,
		}
		return React.cloneElement(oldButton, newProps)
	}
	// Add props to credit card children
	cloneCreditCardElement(oldCreditCardElement) {
		if (!this.creditCardRef) {
			this.newInputsInitialized = true
			this.creditCardRef = React.createRef()
			this.newInputValues.creditCard = false
			this.newInputInvalidFormats.creditCard = false
		}
		let newProps = {
			onChange: this.handleUserInput,
			onReady: this.onCreditCardReady,
		}
		this.currentInputNames.push('creditCard')
		return React.cloneElement(oldCreditCardElement, newProps)
	}

	// CreditCardSection is an iFrame, so add its ref when it is mounted
	onCreditCardReady(creditCardElement) {
		this.creditCardRef.current = creditCardElement
	}

	isInputElement(child) {
		return (
			this.isTextInput(child) ||
			this.isCheckbox(child) ||
			this.isFileInput(child) ||
			this.isSelectInput(child) ||
			this.isMapInput(child)
		)
	}
	isButtonElement(child) {
		// Today we only support Button.jsx, but others can be added as well. If the button is set to excludeFromForm, it will be ignored by the form
		return child?.type === Button && !child.props.excludeFromForm
	}
	isCreditCardElement(child) {
		// Today we only support CreditCardSection, but others can be added as well
		return child?.type === CreditCardSection
	}

	// Input elements can be either checkbox, text, textarea or file.
	isCheckbox(child) {
		// First check is for iterating over children where the type will be the React class
		// Second check is for checking the input element attributes in this.getInvalidInputForSubmit
		return child?.type === CheckboxInput || (child?.nodeName === 'INPUT' && child?.type === 'checkbox')
	}
	isTextInput(child) {
		// First check is for iterating over children where the type will be the React class
		// Second check is for checking the input element attributes in this.getInvalidInputForSubmit
		return (
			!this.isMapInput(child) &&
			child?.type !== 'checkbox' &&
			child?.type !== 'file' &&
			(child?.type === TextInput ||
				child?.type === TextAreaInput ||
				child?.type === DateInput ||
				child?.type === TimeInput ||
				child?.type === PasswordInput ||
				child?.nodeName === 'INPUT' ||
				child?.nodeName === 'TEXTAREA')
		)
	}
	isMapInput(child) {
		return child?.type === MapInput || (child?.nodeName === 'INPUT' && child?.getAttribute('type') === 'map')
	}
	isFileInput(child) {
		// First check is for iterating over children where the type will be the React class
		// Second check is for checking the input element attributes in this.getInvalidInputForSubmit
		return child?.type === ImageInput || child?.type === 'file'
	}
	isSelectInput(child) {
		// First check is for iterating over children where the type will be the React class
		// Second check is for checking the input element attributes in this.getInvalidInputForSubmit
		return child?.type === SelectInput || child?.type === 'select-one'
	}

	setInputParentTogglers(name, parentTogglers) {
		this.inputParentTogglers[name] = parentTogglers
	}

	// Called when an input's value changes and updates state with new value
	handleUserInput(event, newMiscData, optionalOnChange) {
		const { inputInvalidFormats, inputValues, miscData } = this.state
		const target = event.target
		if (target) {
			// Is an input event
			const name = target.name
			// If input used to be invalid but now has changed, we allow for the form the be submitted again, unless other invalid inputs are still present
			inputInvalidFormats[name] = false
			let value
			if (this.isTextInput(target) || this.isSelectInput(target)) {
				value = target.value
				if (target.type === 'number' && (parseInt(target.value) || parseInt(target.value) === 0)) {
					value = parseInt(target.value)
					// Check if a min/max is set, and if so if the input value is in the correct range
					if (target.min && value < parseInt(target.min)) inputInvalidFormats[name] = true
					if (target.max && value > parseInt(target.max)) inputInvalidFormats[name] = true
				}
				if (target.required && !value?.toString().replace(/\s/g, '').length) inputInvalidFormats[name] = true
			} else if (this.isMapInput(target)) {
				value = newMiscData?.googleAddress ? newMiscData.googleAddress : event.target.value // We only want to submit the address returned by Google since it always has the correct format, as opposed to the address formatted by the user
				if (!newMiscData?.newAddressFound && miscData[name].googleAddress !== value) inputInvalidFormats[name] = true // The user changed Google's formatted address, so error highlight it until the user chooses an address returned by Google in the results dropdown list
			} else if (this.isCheckbox(target)) {
				value = event.target.checked
				if (target.required && !value) inputInvalidFormats[name] = true
			} else if (this.isFileInput(target)) {
				if (target.files.length > 0) {
					const reader = new FileReader()
					reader.onload = () => {
						inputValues[name] = reader.result
						this.setState({
							inputValues,
							inputInvalidFormats,
						})
					}
					reader.readAsDataURL(target.files[0])
					return
				} else {
					value = inputValues[name]
				}
			}
			if (optionalOnChange) optionalOnChange(value)
			inputValues[name] = value
			if (newMiscData) miscData[name] = newMiscData
		} else {
			// Is assumed to be credit card event since it uses its own type of onChange and hence the event parameter isn't an event
			// event.complete is set to true by Stripe when credit card is of correct format
			inputValues.creditCard = event.complete
			inputInvalidFormats.creditCard = !event.complete
		}
		this.setState({
			inputValues,
			inputInvalidFormats,
			miscData,
		})
		if (this.props.onChange) this.props.onChange(inputValues, this.getInvalidInputForSubmit())
	}

	// Called when submitting the form. Only submits it if all input values are correctly formatted and if the form isn't
	// already currently being submitted
	handleSubmit(event, callback) {
		event?.preventDefault()
		const { submitted } = this.state
		const { onSubmit, forceDisable } = this.props

		const invalidInput = this.getInvalidInputForSubmit()
		if (submitted) {
			// Form is already submitted. Meant to hinder users from submitting the same form data twice
			return
		} else if (forceDisable) {
			// The form is forced to be disabled, so return
			return
		} else if (invalidInput) {
			// One of the required inputs is missing or of invalid format. Scroll to it and focus it
			this.onInvalidInputFormat(invalidInput)
			return
		} else {
			this.setState({ submitted: true })
			onSubmit(this.filteredInputValues(), this.onSubmitFinished, callback)
		}
	}

	// Scenarios:
	// 1. No error message: errorMessage = undefined
	// 2. Custom error message: errorMessage = string
	// 3. Server error messages: errorMessage = server error (error messages in error.response.data.errorMessages)
	// 4. Server error: errorMessage = graphQL error (no error messages in error.response.data.errorMessages)

	// NEEDS to be called after the form is submitted, if the form should be able to submit again e.g. if the form submission fails.
	onSubmitFinished(errorMessage, onSuccess) {
		const initialErrorMessage = errorMessage
		const { t } = this.props

		//Scenario 3, if no errors en empty string is returned
		if (errorMessage?.errors) {
			errorMessage = errorMessage.errors.length > 0 ? errorMessage.errors[0].message : ''
			//Scenario 4
		} else if (errorMessage?.graphQLErrors) {
			errorMessage = t('copy:checkoutPage.genericError')
		}
		if (!errorMessage) {
			if (this.props.showSavedText && typeof initialErrorMessage !== 'undefined') {
				// Show the saved text on the submit button for 2 seconds
				// Only do this if onSubmitFinished is called as part of a submit, i.e. initialErrorMessage exists, so not when it is called w/o parameters like onSubmitFinished() which can be used sometimes to cancel the submit
				if (this.state.showSavedTextTimeout) {
					// Reset the timeout if the form is submitted again and the old timeout hasn't finished
					clearTimeout(this.state.showSavedTextTimeout)
				}
				const showSavedTextTimeout = setTimeout(
					() => this.setState({ showSavedText: false, showSavedTextTimeout: null }),
					2000,
				)
				this.setState({ showSavedText: true, showSavedTextTimeout })
			}
			if (onSuccess) onSuccess()
		}
		this.setState({ submitted: false, errorMessage })
	}

	// Format on input (e.g. email, phone) was invalid.
	// Highlight that input and disable the submit button until the input's value changes
	onInvalidInputFormat(fieldName) {
		const inputInvalidFormats = this.state.inputInvalidFormats
		inputInvalidFormats[fieldName] = true
		const parentTogglers = this.inputParentTogglers[fieldName]
		if (parentTogglers) {
			for (let parentToggler of parentTogglers) {
				// The input is inside one or more ToggleableElements, so open them before we scroll to it
				if (parentToggler) parentToggler(true)
			}
		}
		this.setState(
			{
				inputInvalidFormats,
			},
			() => {
				pageScroller(fieldName + 'Parent', true, () => {
					const element = this[fieldName + 'Ref'].current
					if (element) {
						element.focus()
					}
				})
			},
		)
	}

	// Checks if one of the input values doesn't fulfill the requirements for submit, and if so returns its name
	// This has higher requirements than inputInvalidFormats. inputInvalidFormats checks and highlights inputs in real time (e.g. if the user removes a value for a required field),
	// while this method also checks for e.g. email/phone formats which are required for submitting (which we don't want in real time since it can be annoying for the user to get format error highlighting when they haven't even finished typing their email/phone)
	getInvalidInputForSubmit() {
		const { inputValues, inputInvalidFormats } = this.state
		for (const [inputName, inputValue] of Object.entries(inputValues)) {
			if (inputInvalidFormats[inputName]) return inputName
			const element = this[inputName + 'Ref']?.current
			if (element && this.isTextInput(element)) {
				const strippedValue = inputValue?.toString().replace(/\s/g, '')
				if (element.required && !strippedValue.length) {
					// Make sure text exists and doesn't only consist of white space
					return inputName
				}
				if (strippedValue.length) {
					if (element.getAttribute('data-type') === 'email') {
						if (!isEmail(inputValue)) {
							return inputName
						}
					} else if (element.getAttribute('data-type') === 'tel') {
						if (!isMobilePhone(inputValue)) {
							return inputName
						}
					} else if (element.getAttribute('data-type') === 'password') {
						if (inputValue.length < 8) {
							return inputName
						}
					} else if (element.getAttribute('data-type') === 'time') {
						const timeArray = inputValue.split(':')
						if (
							inputValue.length !== 5 ||
							!inputValue.match(/^\d\d:\d\d$/) ||
							Number(timeArray[0]) > 23 ||
							Number(timeArray[1]) > 59
						) {
							return inputName
						}
					}
				}
			} else if (element?.required && this.isCheckbox(element) && !inputValue) {
				return inputName
			} else if (element?.required && this.isSelectInput(element) && !inputValue) {
				return inputName
			} else if (element?.required && this.isMapInput(element) && !inputValue) {
				return inputName
			} else if (element?.required && this.isFileInput(element) && !inputValue) {
				return inputName
			} else if (inputName === 'creditCard' && !inputValue) {
				return inputName
			}
		}
	}

	// Filter input values sent to server
	filteredInputValues() {
		const { inputValues, miscData } = this.state
		for (const [inputName, inputValue] of Object.entries(inputValues)) {
			const element = this[inputName + 'Ref']?.current
			if (this.isFileInput(element) && !isLocalImage(inputValue)) {
				// The image is not a local image selected from the user's computer, so don't try to upload it
				// It is most likely a picture ID for their image on cloudinary
				delete inputValues[inputName]
			} else if (this.isSelectInput(element) && !inputValue) {
				// No option in the select input was selected
				delete inputValues[inputName]
			} else if (this.isMapInput(element)) {
				inputValues.longitude = miscData[inputName].longitude
				inputValues.latitude = miscData[inputName].latitude
			} else if (this.isTextInput(element) && element.type === 'number') {
				inputValues[inputName] = parseInt(inputValue)
			}
		}
		return inputValues
	}

	onErrorModalClose() {
		this.setState({ errorMessage: false })
	}

	returnValuesAndRefsToParent() {
		let { inputValues } = this.state
		let inputValuesAndRefs = {}
		for (let [inputName, inputValue] of Object.entries(inputValues)) {
			inputValuesAndRefs[inputName] = { value: inputValue, ref: this[inputName + 'Ref'] }
		}
		inputValuesAndRefs.submitButton = { ref: this.submitButtonRef }
		return inputValuesAndRefs
	}

	render() {
		this.currentInputNames = []
		const { t, tReady, onSubmit, children, autoComplete, onChange, ...rest } = this.props
		const { errorMessage } = this.state
		// Using noValidate to disable Browsers own validation, since I prefer to use our own highlighting

		// Using autoComplete by default to allow for browsers to autocomplete input values.
		// This will for example autofill the user's name, credit card, email, phone if cached in the browser
		return (
			<>
				<StyledForm onSubmit={this.handleSubmit} autoComplete={autoComplete || 'on'} noValidate {...rest}>
					{this.recursiveWrapper(children)}
				</StyledForm>
				<DynamicModal displayModal={!!errorMessage} setDisplayModal={this.onErrorModalClose} zIndex={9001}>
					<CenteredSection>
						{errorMessage}
						<br />
						<br />
						<br />
						<Button onClick={() => this.onErrorModalClose(false)}>{t('common:buttons.tryAgain')}</Button>
					</CenteredSection>
				</DynamicModal>
			</>
		)
	}
}

const StyledForm = styled.form`
	width: 100%;
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}
	${({ nonWhiteBackground }) =>
		nonWhiteBackground &&
		css`
			input {
				background: white;
			}
			button[data-disable='true'] {
				background: ${theme.colors.gray};
			}
		`}
`
const Form = props => {
	const { t } = useTranslation()
	const { userState, dispatch } = useContext(UserContext)
	return <FormEngine {...props} t={t} user={userState.user} />
}

Form.propTypes = {
	autoComplete: PropTypes.string,
	showSavedText: PropTypes.bool, // Set to true if a new text should show in the submit button for 2 seconds if the form successfully saves. That button then needs the savedText prop
	nonWhiteBackground: PropTypes.bool, // Can be used to adapt colors etc for non-white backgrounds.
	onSubmit: PropTypes.func.isRequired, // A callback for when the form is ready for submit, i.e. all inputs are valid
	onChange: PropTypes.func, // A callback for when the form updates input values
	onLoad: PropTypes.func, // A callback for when the form loads
	forceDisable: PropTypes.bool, // Can be used to force the form to disable a submit and the submit button
	valuesAndRefsCallback: PropTypes.func, // A callback for a parent to get the current input values and refs
	manualSubmitTrigger: PropTypes.func, // A function that can be used to manually trigger the form's submit
}

export default Form
