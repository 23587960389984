import React from 'react'
import { theme } from '../../../utils/theme'
import gql from 'graphql-tag'
import { useQuery } from 'urql'
import styled from 'styled-components'
import { isPhone, isTablet } from '../../../utils/screenSizeHelper'

import {
	CenteredPageSection,
	DynamicPageSection,
	DynamicPageSectionRow,
} from '../../reusable_components/layout/PageComponents'
import ResponsiveImage from '../../reusable_components/responsive/ResponsiveImage'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import Close from '../../reusable_components/svg_icons/Close'
import LoadingSpinner from '../../reusable_components/other/LoadingSpinner'

const getImages = gql`
	query ($id: ID!) {
		course(id: $id) {
			imageGallery {
				publicId
			}
		}
	}
`

const StyledImage = styled.div`
	${theme.breakpoints.tablet.upToAndIncluding} {
		> div {
			cursor: pointer;
			left: 0;
			top: 0;
			> svg {
				display: none;
				color: white;
				position: absolute;
				top: ${theme.margins.small};
				right: ${theme.margins.small};
			}
		}
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		svg {
			display: none;
		}
	}
`
const Image = props => {
	const onClick = event => {
		if (!(isPhone() || isTablet())) return
		const image = event.target.parentElement
		const svg = image.lastChild
		if (image.style.position === 'absolute') {
			// The image is opened, so close it
			image.style.position = 'relative'
			image.style.zIndex = '0'
			svg.style.display = 'none'
		} else {
			// The image is closed, so open it
			image.style.position = 'absolute'
			image.style.zIndex = '1'
			svg.style.display = 'block'
		}
	}
	return (
		<StyledImage onClick={onClick}>
			<ResponsiveImage {...props}>
				<Close />
			</ResponsiveImage>
		</StyledImage>
	)
}

const ImageGallery = ({ courseId, title, t }) => {
	const [result] = useQuery({
		query: getImages,
		variables: { id: courseId },
	})
	const { data, fetching, error } = result
	if (fetching || error)
		return (
			<CenteredPageSection>
				<LoadingSpinner />
			</CenteredPageSection>
		)
	const images = data.course.imageGallery
	return (
		<>
			<DynamicPageSection margins={{ top: theme.margins.smallInt, bottom: theme.margins.smallInt }}>
				<h2>{t('copy:coursePage.images')}</h2>
			</DynamicPageSection>
			<div style={{ position: 'relative' }}>
				<DynamicPageSectionRow
					margins={{
						top: theme.margins.smallInt,
						bottom: theme.margins.largeInt,
						inner: theme.margins.XSmallInt,
					}}
					childrenPerRowMobile={2}
					childrenPerRowDesktop={4}
				>
					{images.map((image, key) => (
						<React.Fragment key={key}>
							<Breakpoint breakpoint={theme.breakpoints.phone.range}>
								<Image
									imagePath={image.publicId}
									type="SQUARE"
									alt={title}
									sourceWidth={theme.breakpoints.phone.maxInt}
								/>
							</Breakpoint>
							<Breakpoint breakpoint={theme.breakpoints.tablet.range}>
								<Image
									imagePath={image.publicId}
									type="SQUARE"
									alt={title}
									sourceWidth={theme.breakpoints.tablet.maxInt}
								/>
							</Breakpoint>
							<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
								<Image
									imagePath={image.publicId}
									type="SQUARE"
									alt={title}
									sourceWidth={theme.widths.pageMaxWidthIncludingPaddingInt / 4}
								/>
							</Breakpoint>
						</React.Fragment>
					))}
				</DynamicPageSectionRow>
			</div>
		</>
	)
}

export default ImageGallery
