import styled from 'styled-components'
import { limitRows } from '../PageMixins'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next-new'
import LinkButton from '../buttons/LinkButton'
import { theme } from '../../../utils/theme'

const ReadMoreContent = styled.div`
	${({ maxLines }) => limitRows(maxLines)}
	p {
		display: inline;
	}
	// Since the child paragraph is inline, it can not have a max-width. Therefore we need to set the max-width on this div, since it is not inline and therefore can have a max-width
	// The reason we need the max-width to work is so the value for number of rows works, since different max-widths will produce different number of rows
	max-width: ${theme.widths.textMaxWidth};
	// Only add margin if we display the "Read more" button
	margin-bottom: ${({ displayContent }) => (displayContent === 'HIDE_CONTENT' ? theme.margins.XSmall : 0)};
`

// Wrapper for text that displays the full content when the user clicks on "Read more".
// The full content can also be viewed automatically if the text already fits by itself, according to the specified max number of lines
const ReadMore = ({ children, maxLines = 10, color = theme.colors.textColor }) => {
	const { t } = useTranslation()
	const [contentStatus, setContentStatus] = useState('LOADING')
	const ref = useRef(null)
	const displayContentIfNotOverflown = () => {
		let contentElement = ref.current
		// The full content is now displayed, i.e. contentElement is removed, so remove event listener
		if (!contentElement) {
			window.removeEventListener('resize', displayContentIfNotOverflown)
			return
		}

		// The child div needs to be a p, so if it is a div (e.g. the Markdown component), count the number of rows of that div's children instead
		if (contentElement.children[0]?.nodeName === 'DIV') contentElement = contentElement.children[0]

		// The "Read more" button takes up two additional rows (lines) under the maxLines paragraph. Therefore we check how many
		// lines the paragraph is in total, and if it is maxLines + 2 we might as well hide the "Read more" button and display all paragraph rows (maxLines + 2)
		// since that takes up the same height as maxLines + the "Read more" button

		// To calculate the rows, we set the paragraph's display to inline. That makes each row get its own css rectangle,
		// as found in child.getClientRects(). However, there are more rectangles than rows for some reason.. Therefore
		// we find the number of rectangles with unique y positions since some rectangles share the same y coordinate (meaning they are on the same row), which should correlate to the number of rows
		let numberOfChildrenLines = 0
		let childrensVerticalPostions = []
		if (contentElement.children?.length > 0) {
			for (let child of contentElement.children) {
				for (let rectangle of child.getClientRects()) {
					childrensVerticalPostions.push(rectangle.y)
				}
			}
			childrensVerticalPostions = new Set(childrensVerticalPostions) // Make the array with y coordinates unique
			numberOfChildrenLines = childrensVerticalPostions.size // Number of rows is number of unique y coordinates
		}
		if (contentElement.scrollHeight === contentElement.clientHeight || numberOfChildrenLines <= maxLines + 2) {
			// The full content is not overflown, i.e. it automatically fits by itself, so remove "Read more" button
			setContentStatus('DISPLAY_CONTENT')
		} else if (contentStatus === 'LOADING') {
			// The content has done its first calculation of height and it higher than maxLines, so display the "Read more" button and hide the content
			setContentStatus('HIDE_CONTENT')
		}
	}
	useEffect(() => {
		window.addEventListener('resize', displayContentIfNotOverflown)
		return () => window.removeEventListener('resize', displayContentIfNotOverflown)
	}, [displayContentIfNotOverflown])
	useEffect(() => {
		displayContentIfNotOverflown()
	}, [])
	return (
		<div style={{ color }}>
			{contentStatus === 'DISPLAY_CONTENT' ? (
				children
			) : (
				<>
					<ReadMoreContent ref={ref} maxLines={maxLines} displayContent={contentStatus}>
						{children}
					</ReadMoreContent>
					{/*When the component first loads, we don't want to display the "Read more" button since we don't know if we need it yet.
			Therefore we initially hide it, and after the first calculation of displayContentIfNotOverflown we know whether to show it or not*/}
					{contentStatus === 'HIDE_CONTENT' && (
						<div>
							<LinkButton onClick={() => setContentStatus('DISPLAY_CONTENT')}>
								{t('common:buttons.readmore')}
							</LinkButton>
						</div>
					)}
				</>
			)}
		</div>
	)
}

export default ReadMore
