import styled from 'styled-components'

// Is a button but looks like an underlined link
// Use this instead of an <a> tag without a href
const LinkButton = styled.button`
	border: none;
	background: none;
	outline: none;
	text-decoration: underline;
	padding: 0;
	color: ${({ color }) => color || 'inherit'};
`
export default LinkButton
