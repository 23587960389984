import React, { useEffect, useRef } from 'react'
import { theme } from '../../../utils/theme'
import PropTypes from 'prop-types'

import MobileModal from './MobileModal'
import OverlayModal from './OverlayModal'
import Breakpoint from '../responsive/Breakpoint'

// Includes both a mobile modal and desktop overlay modal depending on screen size
const DynamicModal = ({ children, setDisplayModal, displayModal, id, modalType, ...rest }) => {
	const modalId = useRef(null)
	const childProps = {
		onClose: () => {
			const url = new URL(location.href)
			const modalCount = url.searchParams.get('modal')
			if (parseInt(modalCount) === modalId.current) {
				if (modalCount === '0') {
					url.searchParams.delete('modal')
				} else {
					url.searchParams.set('modal', parseInt(modalCount) - 1)
				}
				window.history.replaceState({}, document.title, url.href)
			}
			modalId.current = null
			setDisplayModal(false)
		},
		displayModal,
		...rest,
	}
	useEffect(() => {
		// Add a popstate listener to close the side bar when the user clicks the browser's back button
		const popStateListener = event => {
			const url = new URL(location.href)
			const modalCount = url.searchParams.get('modal')
			if (displayModal && ((modalId.current === 0 && !modalCount) || parseInt(modalCount) === modalId.current - 1)) {
				modalId.current = null
				setDisplayModal(false)
			}
		}
		if (displayModal) {
			const url = new URL(location.href)
			const modalCount = url.searchParams.get('modal')
			if (!modalCount) {
				modalId.current = 0
			} else {
				modalId.current = parseInt(modalCount) + 1
			}
			url.searchParams.set('modal', modalId.current)
			window.history.pushState({}, document.title, url.href)
			window.addEventListener('popstate', popStateListener)
		}
		return () => {
			window.removeEventListener('popstate', popStateListener)
		}
	}, [displayModal])
	const mobileModal = <MobileModal {...childProps}>{children}</MobileModal>
	const desktopModal = <OverlayModal {...childProps}>{children}</OverlayModal>
	if (modalType) {
		return modalType === 'MOBILE' ? mobileModal : desktopModal
	}

	return (
		<>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>{mobileModal}</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>{desktopModal}</Breakpoint>
		</>
	)
}

DynamicModal.propTypes = {
	displayModal: PropTypes.bool.isRequired, // Should the modal be displayed?
	setDisplayModal: PropTypes.func.isRequired,
	bottomOffset: PropTypes.string, // If the modal shouldn't come from the bottom of the screen, e.g. if there is a fixed footer at the bottom of the screen like on purchase page, the modal can be configured to start above it.
	modalType: PropTypes.string,
}

export default DynamicModal
