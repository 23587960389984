import React from 'react'
import SvgShell from './SvgShell'

const Lightning = props => {
	const style = { fill: 'none', stroke: 'currentColor', strokeWidth: '0.7px' }
	return (
		<SvgShell {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.69 13.66">
				<path
					style={style}
					d="M6,1H3.51s0,0,0,0L1,8.57H3.49v0L2.51,14s0,0,0,0L8,4.81s0,0,0,0H5L6,1Z"
					transform="translate(-0.65 -0.65)"
				/>
			</svg>
		</SvgShell>
	)
}

export default Lightning
