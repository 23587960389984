import styled from 'styled-components'
import React from 'react'
import ScreenListener from './ScreenListener'
import { getCloudinaryImageUrl } from '../../../utils/imageHelper'
import PropTypes from 'prop-types'

const StyledImage = styled.img`
	border-radius: 2px;
	width: ${({ width }) => width}px;
`

// NonResponsiveImages are most commonly server rendered, since we don't care about the screen size
const Image = ({ imagePath, type, alt, width }) => (
	<StyledImage width={width} src={getCloudinaryImageUrl(imagePath, width, type)} alt={alt} />
)

// A NonResponsiveImage always has the same width/height, no matter the screen size.
// It is the opposite of ResponsiveImage which gets a different height/width depending on screen size
function NonResponsiveImage(props) {
	return (
		<ScreenListener>
			<Image {...props} />
		</ScreenListener>
	)
}

NonResponsiveImage.propTypes = {
	imagePath: PropTypes.string.isRequired,
	type: PropTypes.string,
	width: PropTypes.number.isRequired,
}

export default NonResponsiveImage
